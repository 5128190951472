import React from 'react';
import { observable } from 'mobx';
import { observer } from 'mobx-react';

import PageTree from './PageTree';
import proccessLinks from './links';
import store from '../../store';
import { PageNotFound } from '../../pages/404';

@observer
export default class PageForRoute extends React.Component {
	
	@observable page = null;
	@observable error = null;
	
	constructor(props) {
		super(props);
		this.init();
	}
	
	componentDidUpdate(prevProps, prevState, snapshot) {
		if (prevProps.route !== this.props.route) {
			this.init();
		}
	}
	
	init = async () => {
		const { error, page } = await store.model.Page.findPage({ route: this.props.route });
		console.log({ error, page });
		if (!error) {
			this.page = page;
		}
		else {
			this.error = error;
		}
	};
	
	onMutation = (mutationsList, observer) => {
		proccessLinks(this.el);
		// proccessAccordeons(this.el);
	};

	onMount = el => {
		// console.log('onMount', el);
		if (el) {
			this.el = el;
			const observer = new MutationObserver(this.onMutation);
			observer.observe(el, {
				// attributes: true,
				childList: true,
				subtree: true
			});
			proccessLinks(el);
			// proccessAccordeons(el);
		}
	};
	
	render() {
		if (!this.page && this.error) return <PageNotFound />
		if (!this.page) return null;
		
		return <PageTree tree={this.page} onMount={this.onMount}/>;
	}
	
}
