import React from 'react';
import PropTypes from 'prop-types';
import { observable } from 'mobx';
import { observer } from 'mobx-react';
import { Link } from 'react-router-dom';
import classNames from 'classnames';
import store from 'client/store';
import './style.scss';
import { JPEG_QUALITY } from 'constants';

@observer
export default class Banner extends React.Component {

	static propTypes = {
		width: PropTypes.number,
		height: PropTypes.number,
		place: PropTypes.number,
		banner: PropTypes.object,
		placeholder: PropTypes.any,
	};

	static defaultProps = {
		place: 0,
	};

	@observable banner = null;
	@observable isLoading = true;

	constructor(props) {
		super(props);
		if (!props.banner) this.init();
		if (props.banner) this.banner = props.banner
	}

	init = async () => {
		const { width, height, place } = this.props;
		const banners = store.ui.banners.filter(item => item.width === width && item.height === height)
		this.banner = banners.length > 0 ? banners[0] : null;
		this.isLoading = false;
	};

	render() {
		let { banner, width, height, place, placeholder } = this.props;

		if (!banner) {
			if (this.isLoading) return <div className={`banner-${width}x${height}`} />;
			if (!this.banner || (!this.banner.filename && !this.banner.html)) {
				return placeholder ? <div className={`banner-${width}x${height}`}>{placeholder}</div> : null;
			}
			banner = this.banner;
		}
		else {
			width = banner.width;
			height = banner.height;
			place = banner.place;
		}
		const isGif = banner.filename.toLowerCase().includes('.gif')
		const resize = '' // isGif ? '' : ',' + JPEG_QUALITY;

		const img = <img src={banner.downloadFile('filename') + `?image=[{"resize":{"width":${width}}}${resize}]`} style={{ width, height }} alt="" />;
		const link = banner.link;
		const html = banner.html;
		// console.log('banner', this.banner.filename, link);
		let className = classNames(`banner-${width}x${height}`, `place-${place}`, {
			linked: !!link,
		});
		// замена класс нейма из-за попадания в аддблок хрома
		if (width === 468 && height === 60) {
			className = classNames(`banner-${width}xx${height}-`, `place-${place}`, {
				linked: !!link,
			});
		}

		return <div className={className}>
			{ html ? <div dangerouslySetInnerHTML={{ __html: html }} /> : 
				(
					link ?
					/^http/.test(link) ? <a target="_blank" href={link}>{img}</a> : <Link to={link}>{img}</Link>
					:
					img
				)
			}
		</div>;
	}
}
