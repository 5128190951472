import React from 'react';
import { observable } from 'mobx';
import { observer } from 'mobx-react';
import MediaQuery from 'react-responsive';

import { MediaCard } from 'components';
import icon from 'client/img/media-icon.svg';
import store from 'client/store';
import './style.scss';
import { Link } from 'react-router-dom';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faChevronRight } from "@fortawesome/free-solid-svg-icons";
@observer
export default class Media extends React.Component {

	@observable records = [];

	constructor(props) {
		super(props);
		this.init();
	}

	init = async () => {
		this.records.totalCount = 0;
		const where = { and: [] }
		where.and.push({ type: 'video' })
		// сателлит
		if (store.local.countyId) where.and.push({ countyId: store.local.countyId });
		// флагман
		else where.and.push({
			or: [
				{ countyId: 1 },
				{ showOnMain: true }
			]
		});
		this.records = await store.model.ViewAlbum.find({
			where,
		
			order: 'id desc',
			limit: 4,
			fields: ['image', 'commentsCount', 'viewsCount', 'id', 'type', 'name']
		})

	};

	render() {
		const sizes = [1, 0, 0, 1, 1, 0];

		if (this.records.length === 0) return null

		return <div className={'media-main-container' + (this.props.last ? ' last' : '')}>
			<div className="media-main">
				<div className='header'>
					<Link to="/media" >
						<h2 className="main" ><img src={icon} alt="" /> Видео</h2>
					</Link>
					<Link to="/media" className='right'>
						<div className="link">Посмотреть все <FontAwesomeIcon icon={faChevronRight} /></div>
					</Link>
				</div>

				<div className="cards">
					{/* {this.records.slice(0, 4).map((item, i) => <MediaCard key={i} media={item} size={sizes[i] ? 'big' : 'small'} />)}
					<MediaQuery minWidth={1024}>
						{this.records.slice(4, 6).map((item, i) => <MediaCard key={i} media={item} size={sizes[i] ? 'big' : 'small'} />)}
					</MediaQuery> */}
					{this.records.map(record => <MediaCard media={record} key={record.id} />)}
				</div>
			</div>
		</div>;
	}

}
