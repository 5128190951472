import React from 'react';
import { observable } from 'mobx';
import { observer } from 'mobx-react';
import { withRouter } from 'react-router-dom';
import { PodcastPlayer, ViewsCountActive } from 'components';
import store from 'client/store';
import { PageNotFound } from '../404';
import List from 'client/pages/podcasts/List';

@withRouter @observer
export default class Single extends React.Component {

	@observable record = null;
	@observable error = null;
	@observable isLoading = true;

	constructor(props) {
		super(props);
		this.init();
	}

	init = async () => {
		try {
			this.record = await store.model.Media.findById(this.props.match.params.id);
		}
		catch (e) {
			this.error = e.message;
		}
		this.isLoading = false;
	};

	componentDidUpdate(prevProps, prevState, snapshot) {
		if (prevProps.match.params.id !== this.props.match.params.id) {
			this.init();
		}
	}

	render() {
		if (this.isLoading) return null;
		if (this.error) return <PageNotFound />

		return <div className="single">
			<div className='view-count'><ViewsCountActive record={this.record} /></div>
			<PodcastPlayer podcast={this.record} single />
			<div className="description" dangerouslySetInnerHTML={{ __html: this.record.description }} />
			<List initCount='4' moreCount='4' noBreadcrumbs={true}/>
		</div>;
	}

}
