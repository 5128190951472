import React from 'react';
import { observable } from 'mobx';
import { observer } from 'mobx-react';
import { Link } from 'react-router-dom';
import classNames from 'classnames';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faChevronRight } from "@fortawesome/free-solid-svg-icons";
import icon from 'client/img/news-icon.svg';
import store from 'client/store';
import './style.scss';
import { OpinionCard } from 'components';

@observer
export default class Opinion extends React.Component {

	@observable articles = [];
	@observable isLoading = true;
	@observable category = null;

	constructor(props) {
		super(props);
		this.init();
	}

	init = async () => {
		this.articles = await store.model.ViewLabel.getArticles({
			willBePublished:true,
			category: this.props.category,
			county: store.local.countyId || undefined,
			showOnMain: !store.local.countyId,
			limit: 4,
			order: 'publishAt desc',
			fields: ['id', 'image', 'title', 'publishAt','commentsCount' , 'viewsCount']
		});
		this.category = await store.model.Category.findById(this.props.category);
		this.isLoading = false;
	};

	processText = html => html.replace(/(<([^>]+)>)/gi, "").substr(0, 300);

	render() {
		const { grey } = this.props;

		const noImage = false;

		let articles = <>
			<div className="block"/>
			<div className="block"/>
			<div className="block"/>
			<div className="block"/>
		</>

		if (!this.isLoading) {
			if (this.articles.length === 0) return null;
			articles = this.articles.map((article) => <OpinionCard article={article} key={article.id} />
			);
		}

		const className = classNames('opinion-container', { grey });

		const title = this.category && this.category.name;
		const route = this.category && this.category.path || '/opinion';

		return <div className={className}>
			<div className={'opinion' + (noImage ? ' no-image' : '')}>
			<div className='header'>
					<Link to={route} >
						<h2 className="main" ><img src={icon} alt="" /> {title} </h2>
					</Link>
					<Link to={route} className='right'>
						<div className="link">Посмотреть все <FontAwesomeIcon icon={faChevronRight} /></div>
					</Link>
				</div>
				<div className="articles">
					{articles}
				</div>
			</div>
		</div>;
	}

}
