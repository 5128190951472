import React from 'react';
import { observable } from 'mobx';
import { observer } from 'mobx-react';

import Lenta from '../news/Lenta';

@observer
export default class Single extends React.Component {

	render() {
		const id = parseInt(this.props.match.params.id);

		return <Lenta magazineId={id} />;
	}

}
