import React from 'react';
import PropTypes from 'prop-types';
import { observable } from 'mobx';
import { observer } from 'mobx-react';
import classNames from 'classnames';

import { Button } from '@smartplatform/ui';
import { Editor, DeleteButton } from 'components';
import EditText from './EditText';
import { relative, stripHTML } from 'client/helpers';
import cStore from './store';
import store from 'client/store';
import t from 'i18n';
import './style.scss';

@observer
export default class Comment extends React.Component {
	
	static propTypes = {
		className: PropTypes.string,
	};
	
	@observable editing = false;
	@observable lightboxImages = [];
	@observable showImages = false;

	changed = false;

	onEditorInit = editor => {
		this.editor = editor;
	};

	onTextChange = value => {
		this.props.comment.text = value;
		this.changed = true;
	};
	
	edit = (e) => {
		e.preventDefault();
		this.editing = true;
		this.origValue = this.props.comment.text;
	};

	save = async (e) => {
		e.preventDefault();
		if (this.changed) {
			this.props.comment.text = this.editor.getContent();
			await this.props.comment.save();
		}
		this.editing = false;
		this.changed = false;
	};
	
	cancel = (e) => {
		e.preventDefault();
		this.editing = false;
		this.changed = false;
		this.props.comment.text = this.origValue;
	};

	processText = html => {
		if (html) {
			html = stripHTML(html);
			html = html.replace(/\n{2,}/g, '\n\n').replace(/\n/g, '<br />');
		}
		return html;
	}

	approve = async () => {
		this.props.comment.publish = true;
		await this.props.comment.save();
	};

	hide = async () => {
		this.props.comment.publish = false;
		await this.props.comment.save();
	};

	render() {
		const { comment, className } = this.props;

		const _className = classNames('comment', className, {
			pending: !comment.publish,
		});
		
		return <div className={_className}>
			<div className="info">
				<span className="author">{comment.name}</span>
				<span className="date">{relative(comment.createdAt)}</span>
			</div>
			<div className="text">
				{this.editing ?
					<EditText
						value={comment.text}
						onChange={this.onTextChange}
						onInit={this.onEditorInit}
					/>
					:
					<div
						className="rich-text"
						dangerouslySetInnerHTML={{ __html: this.processText(comment.text) }}
					/>
				}
			</div>
			{cStore.isAdmin && <div className="actions">
				{!comment.publish ?
					<Button onClick={this.approve} className="btn-primary btn-sm">Подтвердить</Button>
					:
					<Button onClick={this.hide} className="btn-default btn-sm">Скрыть комментарий</Button>
				}
			</div>}
{/*
			{!comment.publish && <div className="actions">
				{(store.model.user && store.model.user.id === comment.ownerId) && <div className="toggle">
					{this.editing ?
						<a href="#" onClick={this.save}>{t('save')}</a>
						:
						<a href="#" onClick={this.edit}>{t('edit')}</a>
					}
					{this.editing && <> <a href="#" onClick={this.cancel}>{t('cancel')}</a></>}
					<DeleteButton onConfirm={() => this.props.onDelete(comment)} confirmMessage={t('comment.confirmDelete')} />
				</div>}
			</div>}
*/}
		</div>;
	}
	
}
