import React from 'react';
import { Switch, Route } from 'react-router-dom';

import List from './List';
import Single from './Single';
import './style.scss';

export const TYPE_PHOTO = 'photo';
export const TYPE_VIDEO = 'video';
export const TYPE_AUDIO = 'audio';

const Media = () => <Switch>
	<Route path="/media" exact component={List} />
	<Route path="/media/tag/:tag" ><List tag/></Route>
	<Route path="/media/:id" component={Single} />

</Switch>;

export default Media;