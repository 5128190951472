import React from 'react';
import { observable } from 'mobx';
import { observer } from 'mobx-react';

import Banner from '../Banner';
import store from 'client/store';
import './style.scss';

@observer
export default class Banners468x60 extends React.Component {

	@observable banners = [];
	@observable isLoading = true;

	constructor(props) {
		super(props);
		this.banners = new Array(2);
		this.init();
	}

	init = async () => {
		const banners = [...this.banners];
		// const { width, height } = this.props;

		const records = store.ui.banners.filter(item => item.width === 468 && item.height === 60)
		records.forEach(record => banners[record.place] = record);
		this.banners = banners;
		this.isLoading = false;
	};

	render() {
		if (this.isLoading) return <div className="banners-468x60-container"/>;
		if (!this.banners.find(i => i)) return null
		return <div className="banners-468x60-container">
			<div className="banners-468x60">
				{this.banners.map((banner, i) => 
					banner && <Banner key={i} banner={banner} width={468} height={60} placeholder={<>Рекламный баннер<br />468x60</>} />
				)}
			</div>
		</div>;
	}

}
