import { observable } from 'mobx';
import { getRoleNames } from 'client/helpers';
import store from 'client/store'

class CommentStore {

	@observable isAdmin = false;
	@observable isInitialized = false;

	init = async () => {
		const roleNames = await getRoleNames();
		this.isAdmin = roleNames.includes('admin') || (store.model.user && store.model.user.magazineId === store.ui.magazine.id);
		// console.log('CommentStore: isAdmin', this.isAdmin);
		this.isInitialized = true;
	};

}

export default new CommentStore();
