import React from 'react';
import { observable } from 'mobx';
import { observer } from 'mobx-react';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faChevronRight } from "@fortawesome/free-solid-svg-icons";
import { Phone, NotPhone, Desktop, BigArticle, MidArticle, SmallArticle, Banner285x366 } from 'components';
import icon from 'client/img/news-icon.svg';
import store from 'client/store';
import './style.scss';
import { Link } from 'react-router-dom';

//* блок аналогичный по вертске News , но с выборкой одной категории и без конструктора

@observer
export default class CategoryNews extends React.Component {

	@observable records = [];
	@observable isLoading = true;
	@observable category = null;

	constructor(props) {
		super(props);
		this.init();
	}

	init = async () => {
		this.category = await store.model.Category.findById(this.props.category);
		this.records = await store.model.ViewLabel.getArticles({
			limit: 10,
			category: this.category ? this.category.id : undefined,
			willBePublished: true,
			showOnMain: !store.local.countyId,
			fields: ['id', 'image', 'title', 'publishAt', 'commentsCount', 'viewsCount', 'countyName'],
		})

		this.isLoading = false;
	};

	render() {
		const titleText = this.category?.name || '';
		let title = null;
		const path = this.category?.path || 'news';

		if (store.local.countyId) {
			title = <h2 className="main">
				<img src={icon} alt="" /> {titleText}
			</h2>;
		}
		else {
			title = <div className='header'>
				<Link to={path} >
					<h2 className="main" ><img src={icon} alt="" /> {titleText} </h2>
				</Link>
				<Link to={path} className='right'>
					<div className="link">Посмотреть все <FontAwesomeIcon icon={faChevronRight} /></div>
				</Link>
			</div>
		};

		return <div className="news-main">
			{title}
			<div className="articles">
				<div className="left">
					<BigArticle article={this.records[0]} />
					{new Array(4).fill(null).map((el, index) => <MidArticle key={index} article={this.records[1 + index]} />)}
					<Phone>
						{new Array(5).fill(null).map((el, index) => {
							return <MidArticle key={index} article={this.records[5 + index]} />
						})}
						<Banner285x366 />
					</Phone>
				</div>
				<Desktop>
					<div className="right">
						{this.records.slice(5, 10).map((article, i) => <SmallArticle key={article ? article.id : 'slot-' + i} article={article} />)}
					</div>
				</Desktop>
			</div>
		</div>;
	}

}
