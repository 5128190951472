import React from "react";
import { observable, action } from "mobx";
import { observer } from "mobx-react";
import { withRouter } from "react-router-dom";
import Lightbox from 'react-image-lightbox';
import 'react-image-lightbox/style.css';
import { ViewsCountActive } from 'components';
import { Pager } from "@smartplatform/ui";
import store from 'client/store';
import { Info } from "../../components/info";

const PER_PAGE = 90;

@withRouter @observer
export default class Single extends React.Component {

	@observable media = [];
	@observable error = null;
	@observable page = 1;
	@observable showLightbox = false;
	@observable index = 0;

	constructor(props) {
		super(props);
		this.loadPhotos();
	}

	loadPhotos = async () => {
		this.media = await store.model.ViewMedia.find({
			where: { albumId: this.props.album.id },
			limit: PER_PAGE,
			skip: (this.page - 1) * PER_PAGE,
			order: 'id asc',
		});
	};

	onPageChange = (page) => {
		this.page = page;
		this.loadPhotos();
	};

	@action showImage = (e, index) => {
		e.preventDefault();
		console.log('showImage', index);
		this.index = index;
		this.showLightbox = true;
	};

	@action hideImage = () => {
		this.showLightbox = false;
		this.index = 0;
	};

	showLightboxImage = (image) => {
		return image.downloadFile('image')
	}

	render() {
		const width = 400;
		const height = 300;

		const photos = this.media.map((media, index) => {
			const url = media.downloadFile('image') + '?image=[{"resize":{"width":400}}]';
			return <div key={media.id} className="photo">
				<a target="_blank" href={url} onClick={e => this.showImage(e, index)}>

					<img src={url + `?image=[{"resize":{"width":${width},"height":${height}}]`} width={width} height={height} alt="" />
					<div className="info">
						<div className="stats">
							<Info record={media} />
						</div>
					</div>
				</a>
			</div>;
		});

		const count = photos.length;

		return <div className="photos">
			<div className="photos-container">
				{photos}
			</div>
			<Pager
				current={this.page}
				itemsPerPage={PER_PAGE}
				totalCount={this.media.totalCount}
				onChange={this.onPageChange}
			/>
			{this.showLightbox &&
				<Lightbox
					mainSrc={this.showLightboxImage(this.media[this.index])}
					nextSrc={this.showLightboxImage(this.media[(this.index + 1) % count])}
					prevSrc={this.showLightboxImage(this.media[(this.index + count - 1) % count])}
					onCloseRequest={this.hideImage}
					onMovePrevRequest={() => this.index = (this.index + count - 1) % count}
					onMoveNextRequest={() => this.index = (this.index + 1) % count}
					toolbarButtons={[
						<ViewsCountActive index={this.index} record={this.media[this.index]} />
					]}
				/>
			}
		</div>
	}
}
