import React from 'react';

// import FacebookIcon from '-!svg-react-loader?name=Icon!client/img/social/facebook.svg';
// import InstagramIcon from '-!svg-react-loader?name=Icon!client/img/social/instagram.svg';
import TelegramIcon from '-!svg-react-loader?name=Icon!client/img/social/telegram.svg';
// import TwitterIcon from '-!svg-react-loader?name=Icon!client/img/social/twitter.svg';
import VKIcon from '-!svg-react-loader?name=Icon!client/img/social/vk.svg';
import YoutubeIcon from '-!svg-react-loader?name=Icon!client/img/social/youtube.svg';

import './style.scss';

export default ({ className, magazine = {} }) => {
	return <div className={'social-block ' + (className || '')}>
		{/*{magazine.instagram && <a target="_blank" href={magazine.instagram}><InstagramIcon /></a>}*/}
		{/*{magazine.facebook && <a target="_blank" href={magazine.facebook}><FacebookIcon /></a>}*/}
		{magazine.vkontakte && <a target="_blank" href={magazine.vkontakte}><VKIcon /></a>}
		{/*{magazine.twitter && <a target="_blank" href={magazine.twitter}><TwitterIcon /></a>}*/}
		{magazine.youtube && <a target="_blank" href={magazine.youtube}><YoutubeIcon /></a>}
		{magazine.telegram && <a target="_blank" href={magazine.telegram}><TelegramIcon /></a>}
	</div>
}