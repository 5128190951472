import React from 'react';
import { Switch, Route } from 'react-router-dom';

import List from './List';
import Single from './Single';
import './style.scss';

export const Events = () => <Switch>
	<Route path="/events" exact component={List} />
	<Route path="/events/:id" component={Single} />
</Switch>;
