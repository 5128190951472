import React from 'react';
import { action, observable } from 'mobx';
import { observer } from 'mobx-react';
import { withRouter } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlus } from '@fortawesome/free-solid-svg-icons';
import { DatePicker, Loader, Pager } from '@smartplatform/ui';
import { Button, MidArticle, NotPhone, Phone } from 'components';
import store from 'client/store';
import Labels from './Labels';
import { SearchCard } from './SearchCard';
import { startOfDay, endOfDay, endOfToday, startOfToday, sub } from 'date-fns';



const PER_PAGE = 10;

const DATES = [
	{ id: 1, text: 'за все время', startDate: null },
	{ id: 2, text: 'сегодня', startDate: startOfToday() },
	{ id: 3, text: 'неделя', startDate: sub(endOfToday(), { days: 7 }) },
	{ id: 4, text: 'месяц', startDate: sub(endOfToday(), { months: 1 }) },
]
@withRouter @observer
export default class Search extends React.Component {

	@observable search = '';
	@observable records = [];
	@observable isLoading = false;
	@observable isInitialized = false;
	@observable page = 1;
	@observable selectedLabels = [];
	@observable showLabelFilter = false;
	@observable showDateFilter = false;
	@observable startDate = null;
	@observable endDate = null;
	@observable datePeriodId = null;
	totalCount = 0;

	constructor(props) {
		super(props);
		this.search = props.match.params.str || '';
		this.init();
	}

	componentDidUpdate(prevProps, prevState, snapshot) {
		if (prevProps.match.params.str !== this.props.match.params.str) {
			this.search = this.props.match.params.str || '';
			this.init();
		}
	}

	@action init = async () => {
		this.isLoading = true;
		if (this.endDate || this.startDate || this.search.length > 0 || this.selectedLabels.length > 0) {
			const params = {
				startDate: this.startDate,
				endDate: this.endDate,
				willBePublished: true,
				search: this.trimmed.length > 0 ? this.trimmed : undefined,
				labels: this.selectedLabels.map(label => label.id),
				limit: PER_PAGE,
				onlyPublished: true,
				county: store.local.countyId,
				showOnMain: !store.local.countyId,
				skip: (this.page - 1) * PER_PAGE,
				order: 'publishAt desc, id desc',
			};

			this.records = await store.model.ViewLabel.getArticles(params);
			this.totalCount = this.records[0] ? this.records[0].totalCount : 0;
		} else this.records = [];
		this.isLoading = false;
		this.isInitialized = true;
	};

	get trimmed() {
		return this.search.trim();
	};

	onChange = e => this.search = e.target.value;
	onKeyDown = e => e.keyCode === 13 && this.submit();
	submit = () => this.props.history.push(`/search/${this.trimmed}`);


	onPageChange = async (page) => {
		this.page = page;
		window.scrollTo({ top: 0, behavior: 'smooth' });
		await this.init();
	};

	onLabelsChange = (labels) => {
		// если добавляем метку, которая уже в списке, то ничего не делаем
		if (this.selectedLabels.length !== labels.length) {
			this.selectedLabels = labels.slice(0);
			this.cleanPage();
			this.init();
		}
	};

	cleanPage = () => this.page = 1;
	cleanDatePeriodId = () => this.datePeriodId = null;
	cleanDateFilter = () => {
		this.datePeriodId = null;
		this.startDate = null;
		this.endDate = null;
	};

	toggleShowLabelsFilter = () => {
		this.showLabelFilter = !this.showLabelFilter;
		if (!this.showLabelFilter) {
			this.selectedLabels = []
			this.cleanPage();
			this.init();
		};
	};

	toggleShowDateFilter = () => {
		this.showDateFilter = !this.showDateFilter;
		if (!this.showDateFilter) {
			this.cleanDateFilter()
			this.cleanPage();
			this.init();
		}
	}

	onDatePeriodChange = (date) => {
		if (date.id !== this.datePeriodId) {
			this.datePeriodId = date.id;
			this.startDate = date.startDate;
			this.endDate = endOfToday(new Date());
		} else {
			this.cleanDateFilter()
		}
		this.cleanPage();
		this.init();
	};

	onStartDateChange = (date) => {
		this.startDate = date ? startOfDay(new Date(date)) : null;
		if (this.startDate && this.startDate > this.endDate) this.endDate = null;
		this.cleanPage();
		this.cleanDatePeriodId();
		this.init();
	};

	onEndDateChange = (date) => {
		this.endDate = date ? endOfDay(new Date(date)) : null;
		if (this.endDate && this.endDate < this.startDate) this.startDate = null;
		this.cleanPage();
		this.cleanDatePeriodId();
		this.init();
	};


	render() {

		return <div className="search-page">
			<div className="panel">

				{/* текстовой фильтр */}
				<div className='text-filter'>
					<input type="text" value={this.search} placeholder="Поиск по тексту" onChange={this.onChange} onKeyDown={this.onKeyDown} />
					<Button onClick={this.submit}>Искать</Button>
				</div>

				{/* фильтр тегов */}
				<div className='filter' >
					<div onClick={this.toggleShowLabelsFilter} className='title'>
						<div className={`filter-svg ${this.showLabelFilter ? 'closed' : 'open'}`}><FontAwesomeIcon icon={faPlus} /></div>
						<span>Теги</span>
					</div>
					{this.showLabelFilter &&
						<Labels
							placeholder='Добавить тег'
							model='ViewArticleLabel'
							onChange={this.onLabelsChange}
						/>
					}
				</div>

				{/* фильтр по дате */}
				<div className="filter">
					<div onClick={this.toggleShowDateFilter} className='title'>
						<div className={`filter-svg ${this.showDateFilter ? 'closed' : 'open'}`}><FontAwesomeIcon icon={faPlus} /></div>
						<span>Период времени</span>
					</div>
					{this.showDateFilter && <div className='date-filter'>
						{DATES.map((date) => <DateLabel onClick={this.onDatePeriodChange} key={date.id} date={date} selected={date.id === this.datePeriodId} />)}
						<div className='date-pickers'>
							<DatePicker
								value={this.startDate}
								onChange={this.onStartDateChange}
								placeholder="Дата (с)" />
							<DatePicker
								value={this.endDate}
								onChange={this.onEndDateChange}
								placeholder="Дата (по)" />
						</div>
					</div>}
				</div>

			</div>
			{!this.isInitialized ?
				<Loader />
				:
				<div className={this.isLoading ? 'results-loading' : 'results'}>
					{this.records.length > 0 ? <div className='cards'>
						{this.records.map(article => <div key={article.id}>
							<NotPhone><SearchCard record={article} /></NotPhone>
							<Phone><MidArticle showCounty={!store.local.countyId || false} showCategory={true} article={article} /></Phone>
						</div>
						)}
						<Pager itemsPerPage={PER_PAGE} totalCount={this.totalCount} current={this.page} onChange={this.onPageChange} />
					</div>
						:
						'Введите критерии поиска'
					}
				</div>
			}
		</div>;
	}
}

const DateLabel = ({ date, onClick, selected }) => {
	const className = selected ? 'date-label selected' : 'date-label';
	return <div className={className} onClick={() => onClick(date)}>
		{date.text}
	</div>
}