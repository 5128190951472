export const getYoutubeId = url => {
	if (url) {
		const match1 = url.match(/https:\/\/youtu.be\/(.+)/);
		if (match1) {
			return match1[1];
		}
		const match2 = url.match(/https:\/\/www.youtube.com\/watch\?v=([^&]+)/);
		if (match2) {
			return match2[1];
		}
	}
	return null;
};
