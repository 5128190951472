import React from 'react';
import { observable } from 'mobx';
import { observer } from 'mobx-react';
import { Link } from 'react-router-dom';
import classNames from 'classnames';

import store from 'client/store';
import './style.scss';
import { JPEG_QUALITY } from 'constants';


@observer
export default class Banner extends React.Component {

	@observable banner = null;
	@observable isLoading = true;

	constructor(props) {
		super(props);
		this.init();
	}

	init = async () => {
		const banners = store.ui.banners.filter(item => item.width === 600 && item.height === 300)
		this.banner = banners.length > 0 ? banners[0] : null;
		this.isLoading = false;
	};

	render() {
		if (this.isLoading) return null;
		if (!this.banner || !this.banner.filename) return null;

		const isGif = this.banner.filename.toLowerCase().includes('.gif')
		const resize = '';//isGif ? '' : ',' + JPEG_QUALITY;;

		const img = <img src={this.banner.downloadFile('filename'+`?image=[{"resize":{"width":600}}${resize}]`)}  alt="" />;
		const link = this.banner.link;

		const className = classNames(`banner-600x300`, `place-0`, {
			linked: !!link,
		});

		return <div className="top-banner-container-600x300">
			<div className={className}>
				{link ?
					/^http/.test(link) ? <a target="_blank" href={link}>{img}</a> : <Link to={link}>{img}</Link>
					:
					img
				}
			</div>
		</div>;
	}
}
