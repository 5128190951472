import React from 'react';
import { observable } from 'mobx';
import { observer } from 'mobx-react';

import { Pager } from '@smartplatform/ui';
import { formatDate } from 'client/helpers';
import store from 'client/store';

const PER_PAGE = 5;

@observer
export default class List extends React.Component {

	@observable records = [];
	@observable page = 1;

	constructor(props) {
		super(props);
		this.init();
	}

	init = async () => {
		this.records = await store.model.Article.find({
			where: {
				categoryId: 8,
				publish: true,
				image: { neq: null },
			},
			include: ['county'],
			order: 'id desc',
			limit: PER_PAGE,
			skip: (this.page - 1) * PER_PAGE,
		});
	};

	onPageChange = page => {
		this.page = page;
		this.init();
	};

	render() {
		return <div className="congratulations-page">
			<div className="page-title">
				<h1>Поздравления</h1>
			</div>
			<div className="congratulations-list">
				{this.records.map(record => <div key={record.id} className="congratulation">
					<div className="image"><img src={record.downloadFile('image') + '?image=[{"resize":{"width":400}}]'} alt="" /></div>
					<div className="info">
						<div className="name">{record.title}</div>
						<div className="dates">
							{formatDate(record.publishAt)}
						</div>
						<div className="text" dangerouslySetInnerHTML={{ __html: record.text }} />
					</div>
				</div>)}
			</div>
			<Pager current={this.page} itemsPerPage={PER_PAGE} totalCount={this.records.totalCount} onChange={this.onPageChange} />
		</div>
	}
}
