import React from 'react';
import { observable } from 'mobx';
import { observer } from 'mobx-react';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faChevronRight } from "@fortawesome/free-solid-svg-icons";
import { Phone, NotPhone, Desktop, BigArticle, MidArticle, SmallArticle, Banner285x366 } from 'components';
import icon from 'client/img/news-icon.svg';
import store from 'client/store';
import './style.scss';
import { Link } from 'react-router-dom';

@observer
export default class News extends React.Component {

	@observable records = [];
	@observable isLoading = true;

	constructor(props) {
		super(props);
		this.init();
	}

	init = async () => {
		const articles = [...new Array(10).map(v => null)];

		const slots = await store.model.ArticlePlace.find({
			where: {
				block: 'news',
				articleId: { neq: null },
				countyId: null,
			},
			order: 'place asc',
		});

		let slotRecords = [];
		if (slots.length > 0) {
			slotRecords = await store.model.ViewLabel.getArticles({
				willBePublished: true,
				articleIds: slots.map(slot => slot.articleId),
				fields: ['id', 'countyName', 'title', 'publishAt', 'image', 'commentsCount', 'viewsCount', 'countyName']
			});
		}

		slots.forEach(slot => {
			articles[slot.place] = slotRecords.find(record => record.id === slot.articleId);
		})
		// console.log('slots', articles);

		const autoRecords = await store.model.ViewLabel.getArticles({
			willBePublished: true,
			category: this.props.category || 1,
			limit: 10 - slotRecords.length,
			skip: 0,
			order: 'publishAt desc',
			showOnMain: true,
			exclude: slots.map(slot => slot.articleId),
			fields: ['id', 'countyName', 'title', 'publishAt', 'image', 'commentsCount', 'viewsCount', 'countyName']
		});

		// console.log('autoRecords', autoRecords, slots.length);
		let index = 0;
		articles.forEach((article, i) => {
			if (!article && index < autoRecords.length) {
				articles[i] = autoRecords[index++];
				// console.log('- empty', i, index, articles[i]);
			}
		});

		// console.log('articles', articles);
		this.records = articles;

		this.isLoading = false;
	};

	render() {
		const titleText = 'Новости';
		let title = null;

		if (store.local.countyId) {
			title = <h2 className="main">
				<img src={icon} alt="" /> {titleText}
			</h2>;
		}
		else {
			title = <div className='header'>
				<Link to="/news" >
					<h2 className="main" ><img src={icon} alt="" /> {titleText} </h2>
				</Link>
				<Link to="/news" className='right'>
					<div className="link">Посмотреть все <FontAwesomeIcon icon={faChevronRight} /></div>
				</Link>
			</div>
		};

		return <div className="news-main">
			{title}
			<div className="articles">
				<div className="left">
					<BigArticle article={this.records[0]} />
					{new Array(4).fill(null).map((el, index) => <MidArticle key={index} article={this.records[1 + index]} />)}
					<Phone>
						{new Array(5).fill(null).map((el, index) => {
							return <MidArticle key={index} article={this.records[5 + index]} />
						})}
						<Banner285x366 />
					</Phone>
				</div>
				<Desktop>
					<div className="right">
						{this.records.slice(5, 10).map((article, i) => <SmallArticle key={article ? article.id : 'slot-' + i} article={article} />)}
					</div>
				</Desktop>
			</div>
		</div>;
	}

}
