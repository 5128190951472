import React from 'react';
import ReactDOM from 'react-dom';
import { AppContainer } from 'react-hot-loader';
import { YMInitializer } from 'react-yandex-metrika';
import store from './store';
import App from './App';

const render = (Component) => {
	ReactDOM.render(
		<AppContainer warnings={false}>
			<>
				<YMInitializer accounts={[65920666]}
					options={{
						clickmap: true,
						trackLinks: true,
						accurateTrackBounce: true,
						webvisor: true
					}} />
				<Component />
			</>
		</AppContainer>,
		document.getElementById('root')
	);
};

const init = async () => {
	await store.init();
	render(App);
};

init();

if (module.hot) {
	module.hot.accept();
}
