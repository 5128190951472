import React from 'react';
import { observable } from 'mobx';
import { observer } from 'mobx-react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronRight } from '@fortawesome/free-solid-svg-icons';
import { Link } from 'react-router-dom';

import { Loader } from '@smartplatform/ui';
import { NotPhone, Phone, PodcastPlayer, PhonePodcastPlayer } from 'components';
import store from 'client/store';
import './style.scss';
import icon from 'client/img/podcast.svg';

@observer
export default class Podcast extends React.Component {
	@observable record = null;
	@observable audio = null;
	@observable isLoading = true;
	@observable isPlaying = false;
	@observable isPlayed = false;

	constructor(props) {
		super(props);
		this.init();
	}

	init = async () => {
		//серверный ремоут метод, который отдаст подкаст улуса,но если его нет,то подкаст с флагмана
		this.record = await store.model.ViewMedia.getPodcast({ countyId: store.local.countyId })
		this.isLoading = false;
	};

	play = async () => {
		if (this.audio) {
			this.isPlaying ? await this.audio.pause() : await this.audio.play();
			this.isPlaying = !this.isPlaying;
			this.isPlayed = true;
		}
	};

	render() {
		if (this.isLoading) {
			return (
				<div className="podcast-main">
					<div className="podcast loading">
						<Loader size={30} />
					</div>
				</div>
			);
		}

		if (!this.record) {
			// если ничего не найдено, то лучше вообще блок подкастов не показывать
			return null;
		}

		const podcast = this.record;

		return (
			<div className="podcast-main">
				<NotPhone>
					<div className="podcast">
						<div className="header">
							<Link to="/podcasts" className="left">
								<img src={icon} alt="" />
								<div className="title">Подкасты</div>
							</Link>
							<Link to="/podcasts" className="right">
								<div className="link">
									Посмотреть все <FontAwesomeIcon icon={faChevronRight} />
								</div>
							</Link>
						</div>
						<PodcastPlayer key={podcast.id} podcast={podcast} />
					</div>
				</NotPhone>
				<Phone>
					<div className="podcast">
						<div className="header">
						<Link to="/podcasts" className="left">
								<img src={icon} alt="" />
								<div className="title">Подкасты</div>
							</Link>
						</div>
						<PhonePodcastPlayer key={podcast.id} podcast={podcast} />
					</div>
				</Phone>
			</div>
		);
	}
}
