import React from 'react';
import { observable } from 'mobx';
import { observer } from 'mobx-react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSearch } from '@fortawesome/free-solid-svg-icons';

import store from 'client/store';

@observer
export default class SearchInput extends React.Component {

	@observable search = '';

	onInputMount = el => this.input = el;

	focus = () => this.input && this.input.focus();

	onChange = e => this.search = e.target.value;

	onKeyDown = e => {
		const trimmed = this.search.trim();
		if (e.keyCode === 13 && trimmed.length > 0) {
			store.route.push({ path: `/search/${encodeURIComponent(trimmed)}` });
		}
	};

	render() {
		return <div className="footer-search" onClick={this.focus}>
			<div className="icon"><FontAwesomeIcon icon={faSearch} /></div>
			<input type="text" value={this.search} onChange={this.onChange} onKeyDown={this.onKeyDown} ref={this.onInputMount} />
		</div>;
	}

}
