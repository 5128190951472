import React from 'react';
import PropTypes from 'prop-types';
import { observable } from 'mobx';
import { observer } from 'mobx-react';

import { Loader } from '@smartplatform/ui';
import { getRoleNames } from 'client/helpers';
import { Comment, PostComment } from '.';
import cStore from './store';
import './style.scss';

const stages = {
	LOADING: 'loading',
	RELOADING: 'reloading',
	DONE: 'done',
	ERROR: 'error',
};

@observer
export default class Comments extends React.Component {

	static propTypes = {
		record: PropTypes.object.isRequired,
		relation: PropTypes.string,
		foreignKey: PropTypes.string,
	};

	static defaultProps = {
		relation: 'comments',
		foreignKey: 'articleId',
	};

	@observable status = stages.LOADING;
	@observable error = null;
	@observable comments = [];
	@observable justPosted = false;

	constructor(props) {
		super(props);
		this.props.instance && this.props.instance({ reload: this.reload });
		this.init();
	}

	init = async () => {
		if (!cStore.isInitialized) await cStore.init();
		this.load(true);
	};

	load = async (full = false) => {
		if (this.status !== stages.LOADING) this.status = stages.RELOADING;
		try {
			let where = undefined;

			if (!cStore.isAdmin) {
				where = { publish: true };
			}

			const comments = await this.props.record[this.props.relation].find({
				where,
				order: 'createdAt asc',
				skip: full ? 0 : this.comments.length,
			});

			this.comments = full ? comments : [...this.comments, ...comments];
			this.status = stages.DONE;
		}
		catch (e) {
			this.error = e.message;
			this.status = stages.ERROR;
		}
	};

	reload = async () => {
		this.load(true);
	};

	onSubmitComment = async () => {
		if (cStore.isAdmin) {
			await this.load();
		}
		else {
			this.justPosted = true;
		}
		this.props.onChange && await this.props.onChange(['updatedAt']);
	};

	onCommentDelete = async (comment) => {
/*
		const attachments = await comment.attachments();
		console.log('attachments', attachments);
		for (let attachment of attachments) {
			console.log('-', attachment.filename);
			await attachment.deleteFile('filename');
			await attachment.delete();
		}
*/
		await comment.delete();
		this.reload();
	};

	render() {
		if (this.status === stages.LOADING) return <Loader />;
		if (this.status === stages.ERROR) return <div className="error">{this.error}</div>;

		const { record, relation, foreignKey } = this.props;

		const comments = this.comments.map(comment => {
			return (
				<Comment
					key={'comment-' + comment.id}
					comment={comment}
					className="task-comment"
					onDelete={this.onCommentDelete}
					editable={false}
				/>
			);
		});

		return <div className="comments">
			<div className="title">Комментарии</div>
			{this.justPosted ?
				<div className="pending">Ваш комментарий проверяется</div>
				:
				<PostComment record={record} relation={relation} foreignKey={foreignKey} onSubmit={this.onSubmitComment} />
			}
			<div className="comments-list">{comments}</div>
		</div>;
	}

}
