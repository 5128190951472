import store from 'client/store';
import {
	Banner1920x195,
	Banner600x300,
	Banners250x250,
	Banners468x60,
	Footer,
	Header,
	NotPhone,
	Phone,
	Mobile,
	Desktop,
} from 'components';
import { observer } from 'mobx-react';
import React from 'react';
import Events from './events/Events';
import Media from './media/Media';
import Memory from './memory/Memory';
import CountyNews from './news/CountyNews';
import News from './news/News';
import Opinion from './opinion/Opinion';
import Podcast from './podcast/Podcast';
import SliderCongrats from './slider-congrats/Slider';
import Slider from './slider/Slider';
import SpecialProjects from './special-projects/SpecialProjects';
import './style.scss';
import { Link } from 'react-router-dom';
import CategoryNews from './news/CategoryNews';

@observer
export default class MainPage extends React.Component {
	render() {
		const categoryNews = store.ui.categoryNews.map(({ id }) => <CategoryNews category={id} key={id} />);

		if (store.local.countyId) {
			return (
				<div className="main-page" key={'county-' + (store.local.countyId || 'root')}>
					<NotPhone>
						<Banner1920x195 />
					</NotPhone>
					<Phone>
						<Banner600x300 />
					</Phone>
					<Header />
					<CountyNews />
					{categoryNews}
					<SliderCongrats />
					<News />
					{/* блок актуально убран */}
					{/* <Promo places={[0, 1, 2, 3]} grey /> */}
					<Banners468x60 />
					<SpecialProjects />
					{/* Литклуб */}
					<Opinion category={18} />
					{/*Мнение*/}
					<Opinion category={2} />
					{/*<Events />*/}
					<Podcast />
					<Media last />
					<Memory />
					<Footer />
				</div>
			);
		}

		return (
			<div className="main-page" key="root">
				<Desktop>
					<Banner1920x195 />
				</Desktop>
				<Mobile>
					<Banner600x300 />
				</Mobile>
				<Header />
				<News />
				{categoryNews}
				<NotPhone>
					<Slider />
				</NotPhone>
				{/* блок актуально убран */}
				{/* <Promo places={[0, 1, 2, 3]} grey /> */}
				<Banners468x60 />
				{/*Мнение*/}
				<Opinion category={2} />
				<NotPhone>
					<Banners250x250 />
				</NotPhone>
				<SpecialProjects />
				{/* Литклуб */}
				<Opinion category={18} />
				{/* Афиша */}
				{/*<Events />*/}
				<Podcast />
				<Media />
				<Footer />
			</div>
		);
	}
}
