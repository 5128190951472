import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import { Trigger, TRIGGER_HOVER } from 'components';
import './style.scss';

export default class Popover extends React.Component {
	
	static propTypes = {
		content: PropTypes.any.isRequired, // own prop
		title: PropTypes.any,
		// position: Trigger.propTypes.position,
		// trigger: Trigger.propTypes.trigger,
		offset: PropTypes.number,
		className: PropTypes.string,
		contentClassName: PropTypes.string, // own prop
		style: PropTypes.object,
		onClick: PropTypes.func,
		onActivate: PropTypes.func,
		onClose: PropTypes.func,
		getInstance: PropTypes.func,
		zIndex: PropTypes.number,
	};
	
	static defaultProps = {
		position: 'top',
		trigger: 'hover',
		offset: 10,
		className: '',
		portalClassName: '',
		style: {},
		zIndex: 9000,
	};

	render() {
		const { content, className, contentClassName, ...rest } = this.props;

		const _className = classNames('sp-popover', className);
		const _contentClassName = classNames('sp-popover-content', contentClassName);

		const popup = <>
			{this.props.title && <div className="sp-popover-header">{this.props.title}</div>}
			<div className={_contentClassName}>{this.props.content}</div>
			<div className="sp-popover-arrow"/>
		</>;

		return <Trigger {...rest} className={_className} popup={popup} portalClassName="sp-popover-popup"/>;
	}

}
