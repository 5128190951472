import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import { Button } from '@smartplatform/ui';
import { Popover } from 'components';
import './style.scss';

class Popconfirm extends React.Component {

	static propTypes = {
		content: PropTypes.any.isRequired,
		title: PropTypes.any,
		// position: Popover.propTypes.position,
		offset: PropTypes.number,
		delay: PropTypes.number,
		startOffset: PropTypes.number,
		className: PropTypes.string,
		contentClassName: PropTypes.string,
		style: PropTypes.object,
		onConfirm: PropTypes.func.isRequired,
		confirmBtn: PropTypes.any,
		cancelBtn: PropTypes.any,
		rejectBtn: PropTypes.any,
		errorMsg: PropTypes.any,
	};

	static defaultProps = {
		confirmBtn: 'Ok',
		cancelBtn: 'Cancel',
		rejectBtn: 'Ok',
		errorMsg: 'Error!',
	};

	constructor(props) {
		super(props);
		this.state = { rejected: null };
	}
	
	onConfirm = async () => {
		if (this.props.onConfirm) {
			try {
				const res = await this.props.onConfirm();
				if (res === false || res.error) {
					let msg = this.props.errorMsg;
					if (res.error) msg = res.error;
					this.setState({ rejected: msg });
				}
				else {
					this.props.onConfirm();
					this.close();
				}
			}
			catch(e) {
				console.error('Popconfirm:', e);
				this.setState({ rejected: e.message });
			}
		}
	};

	getInstance = instance => this.popover = instance;

	close = () => {
		// this.setState({ rejected: null });
		this.popover && this.popover.close();
	};

	onClose = () => this.setState({ rejected: null });
	
	render() {
		const { confirmBtn, cancelBtn, rejectBtn } = this.props;

		const popContent = !this.state.rejected ?
			<>
				{this.props.content}
				<div className="sp-popconfirm-buttons">
					<Button className="btn-primary" onClick={this.onConfirm}>{confirmBtn}</Button>
					<Button onClick={this.close}>{cancelBtn}</Button>
				</div>
			</>
			:
			<>
				{this.state.rejected}
				<div className="sp-popconfirm-buttons">
					<Button size="small" onClick={this.close}>{rejectBtn}</Button>
				</div>
			</>;
		
		const { content, onConfirm, contentClassName, submit, ...sendProps } = this.props;

		const _contentClassName = classNames('sp-popconfirm', contentClassName);
		
		return (
			<Popover
				{...sendProps}
				contentClassName={_contentClassName}
				content={popContent}
				trigger="click"
				onClose={this.onClose}
				getInstance={this.getInstance}>
				{this.props.children}
			</Popover>
		);
	}
	
}

export default Popconfirm;
