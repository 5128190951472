import React from 'react';
import { observable } from 'mobx';
import { observer } from 'mobx-react';
import { Link, withRouter } from 'react-router-dom';
import store from 'client/store';
import { Loader } from '@smartplatform/ui';
import { Breadcrumbs, Button, Info, NotPhone, Phone, PhonePodcastPlayer } from 'components';
import { relative } from 'client/helpers';

const INIT_COUNT = 16;
const MORE_COUNT = 8;

@withRouter
@observer
export default class List extends React.Component {
	@observable records = [];
	@observable isLoading = true;

	constructor(props) {
		super(props);
		this.init();
	}

	init = async () => {

		const where = {
			and: [
				{ type: 'podcast' },
				{ audioFile: { neq: null } },
				{ image: { neq: null } },
			],
		};

		if (!store.local.countyId) where.and.push({ or: [{ countyId: 1 }, { showOnMain: true }] });


		this.records = await store.model.ViewMedia.find({
			where,
			limit: this.props.initCount ?? INIT_COUNT,
			skip: 0,
			order: 'createdAt desc',
		});
		this.isLoading = false;
	};

	loadMore = async () => {
		const where = {
			and: [
				{ type: 'podcast' },
				{ audioFile: { neq: null } },
				{ image: { neq: null } },
			],
		};

		if (!store.local.countyId) where.and.push({ or: [{ countyId: 1 }, { showOnMain: true }] });

		const records = await store.model.ViewMedia.find({
			where,
			limit: this.records.length === 0 ? INIT_COUNT : (this.props.moreCount ?? MORE_COUNT),
			skip: this.records.length,
			order: 'createdAt desc',
		});
		this.records.push(...records);
		this.records.totalCount = records[0] ? records[0].totalCount : 0;
	};

	render() {
		if (this.isLoading) {
			// показываем loader
			return (
				<div className="podcasts-page">
					<Loader size={30} />
				</div>
			);
		}

		return (
			<div className="podcasts-page">
				<NotPhone>
					{!this.props.noBreadcrumbs && <Breadcrumbs
						crumbs={[{ title: this.source ? this.source.name : 'Подкасты', path: this.props.path }]}
					/>}
					<div className="page-title">
						<h1>{this.source ? this.source.name : 'Подкасты'}</h1>
						<div className="sorting">{/*TODO: Добавить сортировку*/}</div>
					</div>
					<div className="podcasts">
						{this.records.map((podcast, i) => {
							return (
								<Link key={i} to={`/podcasts/${podcast.id}`} className="podcast-item">
									<div className="image">
										<img
											src={podcast.downloadFile('image') + '?image=[{"resize":{"width":400}}]'}
											alt=""
										/>
										<span className="play-btn" />
									</div>
									<div className="title">{podcast.name}</div>
									<div className="info">
										<div className="date">{relative(podcast.createdAt)}</div>
										<div className="stats">
											<Info record={podcast} />
										</div>
									</div>
								</Link>
							);
						})}
					</div>
					<div className="load-more">
						<Button
							onClick={this.loadMore}
							style={{ display: this.records.length === this.records.totalCount ? 'none' : 'block' }}
						>
							Показать еще
						</Button>
					</div>
				</NotPhone>
				<Phone>
					{!this.props.noBreadcrumbs && <Breadcrumbs
						crumbs={[{ title: this.source ? this.source.name : 'Подкасты', path: this.props.path }]}
					/>}
					<div className="page-title">
						<h1>{this.source ? this.source.name : 'Подкасты'}</h1>
						<div className="sorting">{/*TODO: Добавить сортировку*/}</div>
					</div>
					<div className="podcasts">
						{this.records.map((podcast, i) => {
							return <PhonePodcastPlayer key={podcast.id} podcast={podcast} />;
						})}
					</div>
					<div className="load-more">
						<Button
							onClick={this.loadMore}
							style={{ display: this.records.length === this.records.totalCount ? 'none' : 'block' }}
						>
							Показать еще
						</Button>
					</div>
				</Phone>
			</div>
		);
	}
}
