import React from 'react';
import { observable } from 'mobx';
import { observer } from 'mobx-react';

import { Phone, NotPhone, Desktop, Tablet, BigArticle, MidArticle, SmallArticle, Banner285x366 } from 'components';
import icon from 'client/img/news-icon.svg';
import store from 'client/store';
import { Link } from 'react-router-dom';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faChevronRight } from "@fortawesome/free-solid-svg-icons";

@observer
export default class News extends React.Component {

	@observable records = [];
	@observable isLoading = true;

	constructor(props) {
		super(props);
		this.init();
	}

	init = async () => {
		const articles = [...new Array(10).map(v => null)];

		const slots = await store.model.ArticlePlace.find({
			where: {
				block: 'news',
				articleId: { neq: null },
				countyId: store.local.countyId,
			},
			order: 'place asc',
		});
		let slotRecords = [];
		if (slots.length > 0) {
			slotRecords = await store.model.ViewLabel.getArticles({
				willBePublished: true,
				articleIds: slots.map(slot => slot.articleId),
				fields: ['id', 'countyName', 'title', 'publishAt', 'image', 'commentsCount', 'viewsCount','countyName']
			});
		}
		slots.forEach(slot => {
			articles[slot.place] = slotRecords.find(record => record.id === slot.articleId);
		})
		// console.log('county slots', articles);

		const autoRecords = await store.model.ViewLabel.getArticles({
			willBePublished: true,
			category: this.props.category || 1,
			limit: 10 - slotRecords.length,
			skip: 0,
			order: 'publishAt desc',
			showOnMain: false,
			county: store.local.countyId,
			exclude: slots.map(slot => slot.articleId),
			fields: ['id', 'countyName', 'title', 'publishAt', 'image', 'commentsCount', 'viewsCount','countyName']
		});

		// console.log('county autoRecords', autoRecords, slots.length);
		let index = 0;
		articles.forEach((article, i) => {
			if (!article && index < autoRecords.length) {
				articles[i] = autoRecords[index++];
				// console.log('- empty', i, index, articles[i]);
			}
		});

		// console.log('county articles', articles);
		this.records = articles;

		this.isLoading = false;
	};

	render() {
		const title = <div className='header'>
			<Link to="/news" >
				<h2 className="main" ><img src={icon} alt="" /> Новости района</h2>
			</Link>
			<Link to="/news" className='right'>
				<div className="link">Посмотреть все <FontAwesomeIcon icon={faChevronRight} /></div>
			</Link>
		</div>;


		return <div className="county-news-main">
			{title}
			<div className="articles">
				<Desktop>
					<div className="top">
						<BigArticle article={this.records[0]} />
						<MidArticle article={this.records[1]} />
						<Banner285x366 />
					</div>
					<div className="bottom">
						<MidArticle article={this.records[2]} />
						<MidArticle article={this.records[3]} />
						<BigArticle article={this.records[4]} />
					</div>
				</Desktop>
				<Tablet>
					<div className="top">
						<BigArticle article={this.records[0]} />
						<MidArticle article={this.records[1]} />
					</div>
					<div className="bottom">
						<MidArticle article={this.records[2]} />
						<MidArticle article={this.records[3]} />
						<MidArticle article={this.records[4]} />
					</div>
				</Tablet>
				<Phone>
					{new Array(10).fill(null).map((el,index) => <BigArticle article={this.records[index]} />)}
					<Banner285x366 />
				</Phone>
			</div>
		</div>;
	}

}
