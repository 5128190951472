import React from 'react';
import { Link } from 'react-router-dom';

import { Desktop, Mobile, CountyMenu, Social } from 'components';
import SearchInput from './SearchInput';
import Contacts from './Contacts';
import RegInfo from './RegInfo';
import Copyright from './Copyright';
import store from 'client/store';

import logo from 'client/img/new-logo.png'
import 'client/img/phone.svg';
import 'client/img/mail.svg';
import 'client/img/geotag.svg';

import './styles.scss';

const WIDTH = 200;
const HEIGHT = 60;
const RESIZE = `?image=[{"resize":{"width":${WIDTH},"height":${HEIGHT},"fit":"contain","background":"whitesmoke"}}]`;

export default class Footer extends React.Component {

	render() {
		const items = !store.local.countyId ?
			store.ui.menuItems.map(item => <Link key={item.id} to={item.path}>{item.title}</Link>)
			:
			<CountyMenu footer />;

		const dev = <div className="development">Разработка и хостинг <a target="_blank" href="https://smartunit.pro/">SmartUnit</a></div>;

		const logoSrc = store.ui.county ? store.ui.magazine.downloadFile('logo' + RESIZE) : logo;
		const visibleLogo = <Link to='/'><img className="logo-img" src={logoSrc} alt="" /></Link>;

		return <footer>
			<Desktop>
				<div className="footer">
					<div className="footer-top">
						<div className="logo">
							{visibleLogo}
							<span className="age-16">16+</span>
							<Social magazine={store.ui.magazine} />
						</div>
						<SearchInput />
					</div>

					<div className="footer-menu">
						<div className='d-flex'>
							{items}
							{!store.local.countyId && <>
								<Link to="/editorial">Редакция</Link>
								{/*<Link to="/contacts">Контакты</Link>*/}
								<Link to="/project">О проекте</Link>
								<Link to="/vacancy">Вакансии</Link>
							</>
							}
						</div>
					</div>
					<div className="footer-bottom">
						<RegInfo />
						<Contacts />
					</div>
					<a target="_blank" href="https://old.ulus.media/">Старая версия сайта</a>
					<Copyright />
					{dev}
				</div>
			</Desktop>
			<Mobile>
				<div className="footer footer-phone">
					<div className="logo">
						{visibleLogo}
						<span className="age-16">16+</span>
					</div>
					<div className="footer-social">
						<Social magazine={store.ui.magazine} />
					</div>
					<RegInfo />
					<Contacts />
					<a target="_blank" href="https://old.ulus.media/">Старая версия сайта</a>
					<Copyright />
					{dev}
				</div>
			</Mobile>
		</footer>;
	}
}
