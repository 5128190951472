import React from 'react';
import store from 'client/store';
import format from 'date-fns/format';
import { Link } from 'react-router-dom';

const RegInfo = () => {
	let reg = {};

	if (store.ui.magazine) {
		const { certificate, certificateDate, chiefEditor, generalManager } = store.ui.magazine
		reg = {
			certificate,
			certificateDate: certificateDate && format(new Date(certificateDate), 'dd.MM.yyyy'),
			chiefEditor,
			generalManager,
			domain: store.ui.county?.domain 
		}
	}
	
	const period = store.ui.county ? 'ежедневно': '-';

	return (<div className="reg-info">
		{reg.domain && <div>Сетевое издание: <Link to='/'>{reg.domain}</Link></div>}
		{reg.certificate && <div>Регистрационный номер: <em>{reg.certificate}, выдан Роскомнадзором. Периодичность: {period}</em></div>}
		{reg.certificateDate && <div>Дата регистрации: <em>{reg.certificateDate}</em></div>}
		<div>
			{store.ui.county ? <>
					Учредители: <em>Министерство инноваций, цифрового развития и инфокоммуникационных технологий Республики Саха (Якутия) и Государственное автономное учреждение Республики Саха (Якутия) "Сахапечать"</em>.
				</>
				:
				<>
					Учредитель: <em>Государственное автономное учреждение Республики Саха (Якутия) "Сахапечать"</em>.
				</>
			}
		</div>
		<div>Контакты учредителя: <em>г. Якутск, ул. Орджоникидзе, 31
			тел./ факс.: <a href='tel:+74112343917'>8 (4112) 34-39-17</a> E-mail: <a href='mailto:sakhapechat@mail.ru'>sakhapechat@mail.ru</a>
		</em></div>
		{reg.generalManager && <div>Генеральный директор: <em>{reg.generalManager}</em></div>}
		{reg.chiefEditor && <div>Главный редактор: <em>{reg.chiefEditor}</em></div>}
	</div>
	)
}
export default RegInfo;