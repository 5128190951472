import React from 'react';
import { Switch, Route } from 'react-router-dom';

import List from './List';
import Single from './Single';
import './style.scss';

const Magazines = () =><Switch>
	<Route path="/magazines" exact component={List} />
	<Route path="/magazines/:id" component={Single} />
</Switch>;

export default Magazines;