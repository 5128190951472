import React from 'react';
import { observable } from 'mobx';
import { observer } from 'mobx-react';
import { withRouter, Switch, Route } from 'react-router-dom';

import List from './List';
import Single from './Single';
import './style.scss';

@withRouter @observer
export default class Podcasts extends React.Component {

	render() {
		return <div className="podcasts-page">
			<Switch>
				<Route path="/podcasts" exact component={List} />
				<Route path="/podcasts/:id" component={Single} />
			</Switch>
		</div>;
	}

}
