import React from 'react';
import { observable } from 'mobx';
import { observer } from 'mobx-react';
import { Link } from 'react-router-dom';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faChevronRight } from "@fortawesome/free-solid-svg-icons"
// import Poll from '../poll/Poll';
import icon from 'client/img/memory-icon.svg';
import store from 'client/store';
import { Info } from 'components';
import { relative } from 'client/helpers';
import './style.scss';
import CategoryCard from '../../../components/category-card/CategoryCard';

@observer
export default class SpecialProjects extends React.Component {

	@observable records = [];
	@observable isLoading = true;

	constructor(props) {
		super(props);
		this.init();
	}

	init = async () => {
		this.records = await store.model.ViewLabel.getArticles({
			willBePublished: true,
			special: true,
			category: this.props.categoryId,
			// limit: !store.local.countyId ? 2 : 3,
			limit: 4,
			order: 'publishAt desc',
			county: store.local.countyId,
			showOnMain: !store.local.countyId,
			onlyWithImage: true,
			fields: ['id', 'title', 'subtitle', 'image', 'categoryName', 'publishAt', 'commentsCount', 'viewsCount']
		});

		this.isLoading = false;
	};

	render() {
		if (this.records.length === 0) return null;

		return <div className="special-main-container">
			<div className="special-main">
				<div className='header'>
					<Link to="/specialprojects" >
						<h2 className="main" ><img src={icon} alt="" /> Спецпроекты</h2>
					</Link>
					<Link to="/specialprojects" className='right'>
						<div className="link">Посмотреть все <FontAwesomeIcon icon={faChevronRight} /></div>
					</Link>
				</div>
				<div className='cards'>
					{this.records.map(record => <CategoryCard record={record} key={record.id} />)}
					{/* {!store.local.countyId ? <Poll/> : ''} */}
				</div>
			</div>
		</div>;
	}

}
