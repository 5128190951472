import React from 'react';
import store from 'client/store';

const Contacts = () => {
	let contacts = {}
	if (store.ui.magazine) {
		const { email, address, phone } = store.ui.magazine
		contacts = {
			email,
			address,
			phone
		}
	}
	let phoneArray = []
	if (contacts.phone && contacts.phone.match(',')) {
		phoneArray = contacts.phone.split(',')
	}
	else contacts.phone && phoneArray.push(contacts.phone)

	return (
		<div className="contacts">
			<div className='title'>Контакты редакции</div>
			{phoneArray.map(phone => {
				const replaced = phone && phone.replace(/([^\d])/g, '').replace(/^8/, '+7')
				return <div key={phone}><a className='contact-tel'
					href={`tel:${replaced}`}>
					{phone}</a></div>
			})}
			{contacts.email && <div><a className='contact-email' href={`mailto:${contacts.email}`}>{contacts.email}</a></div>}
			{contacts.address && <div className='contact-address'>{contacts.address}</div>}
		</div >
	)
}

export default Contacts;
