import React from 'react';
import { observable } from 'mobx';
import { observer } from 'mobx-react';
// import { Link } from 'react-router-dom';
import { JPEG_QUALITY } from 'client/constants';
import { Slider } from '@smartplatform/ui';
import { Desktop, NotPhone } from 'components';
import store from 'client/store';
import './style.scss';

const currentDot = <svg width="21" height="21">
	<circle cx="11" cy="11" r="9" stroke="black" opacity="0.2" strokeWidth="1" fill="none" />
	<circle cx="10" cy="10" r="9" stroke="white" strokeWidth="1" fill="none" />
	<circle cx="11" cy="11" r="7" stroke="none" fill="black" opacity="0.2" />
	<circle cx="10" cy="10" r="7" stroke="none" fill="white" />
</svg>;

@observer
export default class MainSlider extends React.Component {

	@observable records = [];
	@observable isLoading = true;
	
	constructor(props) {
		super(props);
		this.init();
	}
	
	init = async () => {
		this.records = await store.model.Slide.find({
			where: { type: null },
			order: 'id desc',
		});
		this.isLoading = false;
	};
	
	dotRender = (index, isCurrent) => {
		return isCurrent ? currentDot : <span />;
	};
	
	render() {
		if (this.isLoading) return <div className="slider"/>;
		
		return <NotPhone>
			<div className="slider">
				<Slider transition={250} interval={5000} autoPlay={this.records.length > 1} arrows={true} dots={false} dotRender={this.dotRender}>
					{this.records.map(slide => <div key={slide.id} className="slide">
						{	
							slide.html ? 
							<div dangerouslySetInnerHTML={{ __html: slide.html }}/> :
							(
								slide.link ?
								// <Link target="_blank" to={slide.link}><img src={slide.downloadFile('image') + '?image=[{"resize":{"width":1200}}]'} alt="" /></Link>
								// Исправляет ошибку с неправильным роутингом при использовании линка
								<a target="_blank" href={slide.link}><img src={slide.downloadFile('image') + `?image=[{"resize":{"width":1200}}${JPEG_QUALITY}]`} alt="" /></a>
								:
								<img src={slide.downloadFile('image') + `?image=[{"resize":{"width":1200}}${JPEG_QUALITY}]`} alt="" />
							)
						}
					</div>)}
				</Slider>
			</div>
		</NotPhone>;
	}
}
