import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';

import './style.scss';

export default class Breadcrumbs extends React.Component {

	static propTypes = {
		crumbs: PropTypes.array,
	};

	render() {
		const { crumbs } = this.props;
		const _crumbs = [{ title: 'Главная', path: '/' }, ...crumbs];

		return <div className="breadcrumbs">
			{_crumbs.map((crumb, i) => <span key={'k' + i}>
				{i > 0 ? <span key={'p' + i} className="bc-arr">&gt;</span> : <span key={'p' + i}> </span>}
				{crumb.path ? <Link key={i} to={crumb.path}>{crumb.title}</Link> : <span key={i} className="bc-title">{crumb.title}</span>}
			</span>)}
		</div>;
	}

}
