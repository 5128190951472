import React from 'react';
import { observable } from 'mobx';
import { observer } from 'mobx-react';
import { MemoryCard } from 'components';
import store from 'client/store';
import { Button, Breadcrumbs } from 'components';

const INIT_COUNT = 4;
const MORE_COUNT = 4;

@observer
export default class List extends React.Component {

	@observable records = [];

	@observable totalCount = 0
	@observable isLoading = true;

	constructor(props) {
		super(props);
		this.init();
	}
	init = async () => {
		const params = {
			category: 5,
			onlyPublished: true,
			county: store.local.countyId,
			onlyWithImage: true,
			fields: ['id', 'image', 'title', 'publishAt', 'commentsCount', 'viewsCount', 'date0', 'date1'],
			limit: INIT_COUNT,
			skip: (this.page - 1) * INIT_COUNT,
			order: 'id desc',
		};
		this.records = await store.model.ViewLabel.getArticles(params);
		this.totalCount = this.records[0] ? this.records[0].totalCount : 0;
		this.isLoading = false;
	}

	loadMore = async () => {
		const records = await store.model.ViewLabel.getArticles({
			category: 5,
			onlyPublished: true,
			county: store.local.countyId,
			onlyWithImage: true,
			fields: ['id', 'image', 'title', 'publishAt', 'commentsCount', 'viewsCount', 'date0', 'date1'],
			limit: this.records.length === 0 ? INIT_COUNT : MORE_COUNT,
			skip: this.records.length,
			order: 'id desc',
		});
		this.records.push(...records);
		this.totalCount = records[0] ? records[0].totalCount : 0;
	};



	render() {
		if (this.isLoading) {
			return <div className="memory-list">...</div>
		}

		return <div className="memory-list">
			<Breadcrumbs crumbs={[{
				title: "Слово памяти",
				path: "/memory"
			}]} />
			<div className="page-title">
				<h1>Память</h1>
			</div>

			<div className="memory-cards">
				{this.records.length === 0 ? null : this.records.map(record => <MemoryCard key={record.id} record={record} />)}
			</div>


			<div className="load-more">
				<Button onClick={this.loadMore} disabled={this.records.length === this.totalCount}>Показать еще</Button>
			</div>

		</div>
	}

}
