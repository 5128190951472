import React from 'react';
import { Link } from 'react-router-dom';

const Copyright = () => <div className="copyright">
	{new Date().getFullYear()} © Все права защищены
	<Link to="/policy">Политика конфиденциальности</Link>
	<div>При любом использовании текстовых материалов на иных ресурсах в сети Интернет гиперссылка на источник обязательна. Фотографии, видеоматериалы, и иные мультимедийные продукты могут быть использованы только с письменного согласия редакции и учредителя.</div>
</div>;

export default Copyright;
