import React from 'react';
import { observable } from 'mobx';
import { observer } from 'mobx-react';
import { Link } from 'react-router-dom';

import store from 'client/store';
import './style.scss';

@observer
export default class Counties extends React.Component {

	prevent = (e, county) => {
		if (location.hostname === 'localhost') {
			e.preventDefault();
			store.ui.setCounty(county.id !== 1 ? county.id : null);
			store.route.push({ path: '/' });
		}
	};

	render() {
		return <div className="counties-page">
			<div className="page-title">
				<h1>Районы</h1>
			</div>
			<div className="counties-list">
				{store.ui.counties.map(county => {
					const domain = county.domain || 'ulus.media';
					//! временное решение,пока не настроится ssl на улусах
					let protocol = "http"
					if (county.domain === 'ulus.media') protocol = "https"
					//!
					return <a key={`county-${county.id}`} href={`${protocol}://${domain}`} onClick={e => this.prevent(e, county)} className="county-link">
						{county.name}
						<div className="domain">{domain}</div>
					</a>
				})}
			</div>
		</div>;
	}

}
