import React from 'react';
import PropTypes from 'prop-types';
import { observable } from 'mobx';
import { observer } from 'mobx-react';

// import { Editor } from 'components';
import { stripHTML } from 'client/helpers';

@observer
export default class EditText extends React.Component {

	onInit = (editor) => {
		if (this.props.rich) {
			this.props.onInit(editor);
		}
		else if (editor) {
			this.props.onInit({
				getContent: () => stripHTML(editor.value),
			});
		}
	};

	onChange = (e) => {
		this.props.onChange && this.props.onChange(e.target.value);
	};

	render() {
		const { value } = this.props;

		return <div className="edit-text">
			<textarea
				value={value}
				onChange={this.onChange}
				ref={this.onInit}
				rows={5}
			/>
		</div>;
	}

}
