import React from 'react';
import { observable } from 'mobx';
import { observer } from 'mobx-react';
import { withRouter, Link } from 'react-router-dom';

import { Article } from '../article';
import './style.scss';

@withRouter @observer
export default class Single extends React.Component {

	render() {
		return <Article id={parseInt(this.props.match.params.id)} comments={false} social={false} more={false} />;
	}

}
