import punycode from 'punycode';

export function clipboardCopyrights() {
	const text = 'Подробнее:';
	const body_element = document.getElementsByTagName('body')[0];
	const choose = window.getSelection();
	const location = document.location;
	const myLink = `${location.protocol}//${punycode.toUnicode(location.hostname)}${location.pathname}`;
	console.log('link in buffer', myLink);
	const authorLink = '<br /><br />' + text + ' ' + "<a href='" + myLink + "'>" + myLink + '</a><br />';
	const copytext = choose.toString().replace(/\s{2,}/g, '<br /><br />') + authorLink;
	console.log('copy text', copytext);
	const addDiv = document.createElement('div');
	addDiv.style.position = 'absolute';
	addDiv.style.left = '-99999px';
	body_element.appendChild(addDiv);
	addDiv.innerHTML = copytext;
	choose.selectAllChildren(addDiv);
	window.setTimeout(function () {
		body_element.removeChild(addDiv);
	}, 0);
}
