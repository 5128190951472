import React from 'react';
import { Switch, Route } from 'react-router-dom';

import SearchPage from './Search';
import './style.scss';

export const Search = () =><Switch>
	<Route path="/search" exact component={SearchPage} />
	<Route path="/search/:str" component={SearchPage} />
</Switch>;

