import React from 'react';
import ReactDOM from 'react-dom';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import './style.scss';

export default class Portal extends React.Component {

	static propTypes = {
		className: PropTypes.string,
		style: PropTypes.object,
		getRef: PropTypes.func,
		root: PropTypes.object,
	};

	onMount = el => {
		if (this.props.getRef) this.props.getRef(el);
	};

	render() {
		// return ReactDOM.createPortal(this.props.children, this.el);

		const { root, getRef, className, ...rest } = this.props;
		const rootElement = root || document.body;
		const _className = classNames('sp-portal', className);

		return ReactDOM.createPortal(
			<div className={_className} ref={this.onMount} {...rest}>{this.props.children}</div>,
			rootElement
		);
	}

}
