import React from 'react';
import { observer } from 'mobx-react';
import { Link } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBars } from '@fortawesome/free-solid-svg-icons';

import { Social, Mobile, Desktop, MobileMenu, DeskTopMenu } from 'components';
import logo from 'client/img/new-logo.png';
import 'client/img/new-logo-256.png';
import searchIcon from 'client/img/search-icon.svg';
import store from 'client/store';
import './style.scss';

const WIDTH = 200;
const HEIGHT = 60;
const RESIZE = `?image=[{"resize":{"width":${WIDTH},"height":${HEIGHT},"fit":"contain","background":"white"}}]`;

@observer
export default class Header extends React.Component {
	prevent = e => {
		if (location.hostname === 'localhost') {
			e.preventDefault();
			store.ui.setCounty(undefined);
			store.route.push({ path: '/' });
		}
	};

	render() {
		const domain =
			store.ui.counties[0] && store.ui.counties[0].domain
				? 'https://' + store.ui.counties[0].domain
				: 'http://dev.ulus.media';

		const _logoRoot = (
			<Link to="/">
				<img className="logo-img" src={logo} alt="" />
			</Link>
		);
		const _logo = (
			<a href={domain} onClick={this.prevent}>
				<img className="logo-img" src={logo} alt="" />
			</a>
		);

		const magazine = store.ui.magazine;
		const newVersion = (
			<a className="new-site-ref" href="https://ulus.media">
				Новая версия
			</a>
		);
		return (
			<>
				<div className={'main-header' + (store.ui.county ? ' county' : '')}>
					{store.ui.county ? (
						<Desktop>
							<div className="header-left">
								<Link to="/" className="magazine">
									{magazine && (
										<div className="logo">
											{magazine.logo ? (
												<img
													src={magazine.downloadFile('logo' + RESIZE)}
													height={HEIGHT}
													alt=""
												/>
											) : (
												magazine.name
											)}
										</div>
									)}
									<div className="county">{store.ui.county.name}</div>
								</Link>
							</div>
							{/*<div className="header-center">{_logo}</div>*/}
							<div className="header-right">
								{_logo}
								{newVersion}
								<span className="age-16">16+</span>
								<Social magazine={store.ui.magazine} />
							</div>
						</Desktop>
					) : (
						<Desktop>
							<div className="header-left">{_logoRoot}</div>
							<div className="header-right">
								{newVersion}
								<span className="age-16">16+</span>
								<Social magazine={store.ui.magazine} />
							</div>
						</Desktop>
					)}

					{store.ui.county ? (
						<Mobile>
							<div className="mobile-menu-btn" onClick={() => store.ui.toggleMenu()}>
								<FontAwesomeIcon icon={faBars} />
							</div>
							<div className="main-link">
								<Link to="/">
									{magazine && (
										<div>
											{magazine.logo ? (
												<img src={magazine.downloadFile('logo' + RESIZE)} alt="" />
											) : (
												magazine.name
											)}
										</div>
									)}
								</Link>
							</div>
							{newVersion}
							<Link to="/search" className="search-btn">
								<img src={searchIcon} alt="" />
							</Link>
							{store.ui.menu && <MobileMenu />}
						</Mobile>
					) : (
						<Mobile>
							<div className="mobile-menu-btn" onClick={() => store.ui.toggleMenu()}>
								<FontAwesomeIcon icon={faBars} />
							</div>
							<div className="main-link">{_logoRoot}</div>
							{newVersion}
							<Link to="/search" className="search-btn">
								<img src={searchIcon} alt="" />
							</Link>
							{store.ui.menu && <MobileMenu />}
						</Mobile>
					)}
				</div>
				<Desktop>
					<DeskTopMenu />
				</Desktop>
			</>
		);
	}
}
