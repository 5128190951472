import { action, observable } from 'mobx';

export default class UIStore {
	@observable menu = false;
	@observable menuItems = [];
	@observable counties = [];
	@observable county = null;
	@observable specialProjects = [];
	@observable magazine = null;
	@observable banners = [];
	@observable categoryNews = [];

	constructor(store) {
		this.store = store;
	}

	init = async () => {
		//для сокращение запросов на главной,все запросы uiStore слиты в одном методе
		const { menuItems, specialProjects, magazine, counties, banners, county, categoryNews } =
			await this.store.model.County.getUiStore({
				countyId: this.store.local.countyId,
				domain: this.store.domain !== 'localhost' ? this.store.domain : undefined,
			});
		this.categoryNews = categoryNews;
		this.menuItems = menuItems;
		this.counties = counties.map(county => ({
			...county,
			logoUrl: this.store.model.downloadFile(this.store.model.Magazine, county.magazineId, 'logo'),
		}));
		this.specialProjects = specialProjects;
		this.banners = banners.map(b => new this.store.model.Banner(b));
		this.magazine = new this.store.model.Magazine(magazine);
		this.county = county;

		if (this.store.domain !== 'localhost' && this.county) {
			this.setCounty(county.id !== 1 ? county.id : undefined);
		}
	};

	setCounty = countyId => {
		this.store.local.countyId = countyId;
		this.store.local.save();
		this.county = countyId ? this.counties.find(county => county.id === countyId) : null;
		console.log('county', this.county ? this.county.name : 'null');
	};

	toggleMenu = (value = undefined) => {
		if (value === undefined) {
			this.menu = !this.menu;
		} else {
			this.menu = value;
		}
		// console.log('toggleMenu', value, this.menu);

		if (this.menu) {
			document.body.classList.add('fixed');
		} else {
			document.body.classList.remove('fixed');
		}
	};
}
