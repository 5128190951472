import React from 'react';
import PropTypes from 'prop-types';
import { observable } from 'mobx';
import { observer } from 'mobx-react';
import { Button } from '@smartplatform/ui';
import store from 'client/store';
import { Link } from 'react-router-dom';
import './style.scss';

@observer
export default class Topics extends React.Component {

	static propTypes = {
		relation: PropTypes.string,
	};

	static defaultProps = {
		relation: 'ViewLabel',
	};

	@observable topics = [];
	@observable isLoading = true

	constructor(props) {
		super(props);
		this.init();
	}
	componentDidUpdate(prevProps, prevState, snapshot) {
		if (prevProps.categoryId !== this.props.categoryId ||
			prevProps.countyId !== this.props.countyId) {
			this.init();
		}
	}
	init = async () => {
		this.isLoading = true;
		const categoryId = this.props.categoryId;
		const countyId = store.local.countyId;
		const showOnMain = countyId ? null : true;

		//!!
		const topics = await store.model.ViewArticle.getFilteredTopics({ categoryId, countyId, showOnMain })
		//!!

		this.topics = topics;
		this.isLoading = false;

	};

	onSelect = topic => {
		this.props.onSelect && this.props.onSelect(topic);
	};
	render() {
		const { selected } = this.props;
		if (this.topics.length === 0) return null;

		return <div className="topics-list" >
			<div className='topics-title'>Рубрики </div>
			{this.topics.map((topic) => {

				//если на листе кликнули в метку,то она становится selected
				const className = 'topic' + (selected && selected.id === topic.id ? ' selected' : '');
				//общие пропсы
				const generalProps = { className }
				//1ый кейс - страница материала , 2ой - страница листа с материалами(c функцией для селекта метки)
				const component = this.props.recordId ?
					<Link to={`/${this.props.categoryPath}/topic/${topic.name}`} {...generalProps} > {topic.name} </Link>
					: <div {...generalProps} onClick={() => this.onSelect(topic)}> {topic.name} </div>;

				return <React.Fragment key={topic.id}>
					{component}
				</React.Fragment>
			})}
		</div>
	}
}
