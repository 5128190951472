import React from 'react';
import { observable } from 'mobx';
import { observer } from 'mobx-react';

import { Popup } from '@smartplatform/ui';
import { formatDate } from 'client/helpers';

@observer
export default class CongratulationsCard extends React.Component {

	@observable popup = false;

	constructor(props) {
		super(props);
	}

	showPopup = () => this.popup = true;
	hidePopup = () => this.popup = false;

	render() {
		const { record } = this.props;

		return <div className="congratulation-card" onClick={this.showPopup}>
			<div className="photo">
				<img src={record.downloadFile('image') + '?image=[{"resize":{"width":400}}]'} alt="" />
			</div>
			<div className="fio">{record.title}</div>
			<div className="dates">
				{formatDate(record.publishAt)}
			</div>

			{this.popup && <Popup onClose={this.hidePopup} portalClassName="" className="congratulation-popup">
				<div className="photo">
					<img src={record.downloadFile('image') + '?image=[{"resize":{"width":400}}]'} alt="" />
				</div>
				<div className="fio">{record.title}</div>
				<div className="dates">
					{formatDate(record.publishAt)}
				</div>
				<div className="text" dangerouslySetInnerHTML={{ __html: record.text }} />
			</Popup>}
		</div>;
	}

}
