import React from 'react';
import { observer } from 'mobx-react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTimes, faSearch } from '@fortawesome/free-solid-svg-icons';
import { Link } from 'react-router-dom';

import CountyMenu from '../CountyMenu';
import logo from 'client/img/new-logo.png';
import store from 'client/store';
import './style.scss';

const WIDTH = 200;
const HEIGHT = 60;
const RESIZE = `?image=[{"resize":{"width":${WIDTH},"height":${HEIGHT},"fit":"contain","background":"white"}}]`;
@observer
export default class MobileMenu extends React.Component {
	constructor(props) {
		super(props);
	}

	close = () => {
		store.ui.toggleMenu(false);
	};

	prevent = e => e.stopPropagation();

	render() {
		const magazine = store.ui.magazine;
		const magazineLogo = magazine && (
			<div>{magazine.logo ? <img src={magazine.downloadFile('logo' + RESIZE)} alt="" /> : magazine.name}</div>
		);
		const mainLogo = <img src={logo} alt="" />;
		const items = !store.local.countyId ? (
			// store.ui.menuItems.map(item => <div key={item.id} className="link"><Link onClick={this.close} to={item.path}>{item.title}</Link></div>)
			<>
				<div onClick={this.close} className="link">
					<Link to="/news">Новости</Link>
				</div>
				<div onClick={this.close} className="link">
					<Link to="/specialprojects">Спецпроекты</Link>
				</div>
				<div onClick={this.close} className="link">
					<Link to="/counties">Районы</Link>
				</div>
				<div onClick={this.close} className="link">
					<Link to="/opinion">
						{store.ui.menuItems.find(item => item.path === 'opinion')?.title || 'Мнения/Тус санаа'}
					</Link>
				</div>
				{/* <div onClick={this.close} className="link"><Link to="/stories">Актуально</Link></div> */}
				<div onClick={this.close} className="link">
					<Link to="/peoplegames">Игры народов</Link>
				</div>
				<div onClick={this.close} className="link red">
					<Link to="/partners">Партнерские материалы</Link>
				</div>
				<div onClick={this.close} className="link">
					<Link to="/media">Видео</Link>
				</div>
				<div onClick={this.close} className="link">
					<Link to="/podcasts">Подкасты</Link>
				</div>
				<div onClick={this.close} className="link">
					<Link to="/ads">Реклама</Link>
				</div>
			</>
		) : (
			<CountyMenu onClick={this.close} mobile />
		);

		return (
			<div className="mobile-menu-container" onClick={this.close}>
				<div className="mobile-menu" onClick={this.prevent} onMouseDown={this.prevent}>
					<div className="menu-header">
						<div className="mobile-close-btn" onClick={() => store.ui.toggleMenu()}>
							<FontAwesomeIcon icon={faTimes} />
						</div>
						<div className="main-link">
							<Link to="/">{store.local.countyId ? magazineLogo : mainLogo}</Link>
						</div>
						<div className="search-btn" onClick={this.search}>
							<FontAwesomeIcon icon={faSearch} />
						</div>
					</div>
					<div className="mobile-links">{items}</div>
				</div>
			</div>
		);
	}
}
