import React from 'react';
import { Switch } from 'react-router-dom';

import { Header, Footer } from 'components';
import './style.scss';

export default ({ children }) => <>
	<Header/>
	<div className="inner-page">
		<Switch>
			{children}
		</Switch>
	</div>
	<Footer />
</>;
