import React from 'react';
import PropTypes from 'prop-types';
import { observable } from 'mobx';
import { observer } from 'mobx-react';

import { Button } from '@smartplatform/ui';
import { Editor } from 'components';
import EditText from './EditText';
import store from 'client/store';
import t from 'i18n';

@observer
export default class PostComment extends React.Component {

	static propTypes = {
		record: PropTypes.object.isRequired,
		relation: PropTypes.string,
		foreignKey: PropTypes.string,
		onSubmit: PropTypes.func,
	};
	
	static defaultProps = {
		relation: 'comments',
		foreignKey: 'articleId',
	};
	
	@observable author = '';
	@observable text = '';
	@observable isSubmitting = false;
	@observable error = null;
	
	constructor(props) {
		super(props);
	}

	onEditorInit = editor => {
		this.editor = editor;
	};
	
	onAuthorChange = e => this.author = e.target.value;
	onTextChange = value => this.text = value;

	submit = async () => {
		this.error = null;
		if (this.author.trim().length === 0) {
			this.error = t('comment.emptyAuthor');
			return;
		}
		if (this.text.trim().length === 0) {
			this.error = t('comment.emptyText');
			return;
		}
		try {
			this.isSubmitting = true;

			const comment = new store.model.Comment();
			comment.name = this.author;
			comment.text = this.editor.getContent();
			comment[this.props.foreignKey] = this.props.record.id;
			store.setVisitorCookie();
			await comment.save();

			this.props.onSubmit && this.props.onSubmit(comment);

			this.author = '';
			this.text = '';
		}
		catch(e) {
			this.error = e.message;
		}
		this.isSubmitting = false;
	};

	render() {
		// console.log('>', t('comment'), t);
		return <div className="post-comment">
			{/*<div className="post-title">{t('comment.postTitle')}</div>*/}
			<div className="block">
				<input type="text" value={this.author} onChange={this.onAuthorChange} placeholder="Ваше имя" />
			</div>
			<div className="block">
				<label>Комментарий:</label>
				<EditText
					value={this.text}
					onChange={this.onTextChange}
					onInit={this.onEditorInit}
				/>
			</div>
			<div className="actions">
				<Button onClick={this.submit} className="btn-md btn-primary" disabled={this.isSubmitting || this.text.trim().length === 0}>{t('comment.post')}</Button>
			</div>
			{this.error && <div className="error">{this.error}</div>}
		</div>;
	}
	
}
