import React from 'react';
import PropTypes from 'prop-types';
import { observable } from 'mobx';
import { observer } from 'mobx-react';
import classNames from 'classnames';
import { Switch, Route, withRouter, matchPath } from 'react-router-dom';

import store from '../../store';
import './style.scss';

const GRAD_WIDTH = 50;

@withRouter @observer
export default class Menu extends React.Component {
	
	static propTypes = {
		className: PropTypes.string,
		linksOnly: PropTypes.bool,
	};
	
	static defaultProps = {
		linksOnly: false,
	};
	
	gotoItem = (e, index, item) => {
		console.log('gotoItem', this.dragging);
		store.route.path = item.path;
		store.route.push();
	};
	
	render() {
		const items = [];
		const children = [];
		
		let activeItem = 0;
		
		React.Children.forEach(this.props.children, (child, i) => {
			if (child && child.props && child.props.isItem) {
				const { path, exact } = child.props;
				const match = matchPath(store.route.path, { path, exact });
				if (match) activeItem = i;
				// console.log('>', path, match);
				items.push(child.props);
			}
			else {
				children.push(child);
			}
		});
		
		const _items = items.map((item, i) => (
			<div
				key={i}
				className={'route-menu-item' + (i === activeItem ? ' active' : '')}
				onClick={e => this.gotoItem(e, i, item)}>
				{item.title}
			</div>
		));
		
		const routes = items.map((item, i) => <Route key={i} path={item.path} exact={item.exact} render={() => item.children}/>);
		
		const className = classNames('route-menu-page', this.props.className);
		
		return <div className={className}>
			<div className="route-menu-container" ref={this.onMount}>
				{_items}
			</div>
			<div className="route-menu-content">
				{!this.props.linksOnly && <Switch>{routes}</Switch>}
				{children}
			</div>
		</div>;
	}
	
}
