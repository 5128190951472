import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import { Portal } from 'components';
import POSITIONS, { VERTICAL, HORIZONTAL } from './positions';
import './style.scss';

export const TRIGGER_HOVER = 'hover';
export const TRIGGER_CLICK = 'click';
export const TRIGGER_MANUAL = 'manual';

export default class Trigger extends React.Component {

	static propTypes = {
		popup: PropTypes.any.isRequired,
		title: PropTypes.any,
		position: PropTypes.oneOf(Object.keys(POSITIONS)),
		trigger: PropTypes.oneOf([TRIGGER_HOVER, TRIGGER_CLICK, TRIGGER_MANUAL]),
		offset: PropTypes.number,
		className: PropTypes.string,
		portalClassName: PropTypes.string,
		style: PropTypes.object,
		onClick: PropTypes.func,
		onActivate: PropTypes.func,
		onClose: PropTypes.func,
		getInstance: PropTypes.func,
		onPopupMount: PropTypes.func,
		alwaysActive: PropTypes.bool,
		matchWidth: PropTypes.bool,
		zIndex: PropTypes.number,
	};

	static defaultProps = {
		position: 'top',
		trigger: TRIGGER_HOVER,
		offset: 0,
		className: '',
		portalClassName: '',
		style: {},
		alwaysActive: false,
		matchWidth: false,
		zIndex: 9000,
	};
	
	constructor(props) {
		super(props);
		if (props.getInstance) props.getInstance({
			isActive: this.isActive,
			activate: this.activate,
			close: this.close,
		});
		this.state = { 
			active: props.alwaysActive,
			el: null,
		};
	}

	onMount = el => {
		this.setState({ el });
		// if (el) this.startObserve(el);
	}

	toggle = (state) => {
		const active = state !== undefined ? state : !this.state.active;
		if (active) {
			if (this.props.onActivate) this.props.onActivate();
		}
		else {
			if (this.props.onClose) this.props.onClose();
		}
		this.setState({ active });
	};

	isActive = () => this.state.active;

	activate = () => this.toggle(true);

	close = () => this.toggle(false);

	onClick = e => {
		if (this.state.el && this.state.el.contains(e.target)) {
			if (this.props.trigger === TRIGGER_CLICK) {
				e.stopPropagation();
				this.toggle();
			}
		}
		if (this.props.onClick) this.props.onClick(e);
	};

	onMouseEnter = () => {
		if (this.props.trigger === TRIGGER_HOVER) this.activate();
	};

	onMouseLeave = () => {
		if (this.props.trigger === TRIGGER_HOVER) this.close();
	};

	onTriggerMouseDown = e => {
		if (e.detail > 1) e.preventDefault();
	};

	onPopupMount = el =>  {
		this.popup = el;
		if (this.props.onPopupMount) this.props.onPopupMount(el);
		if (el) {
			// console.log('Popopver: onPopupMount: rect', el.getBoundingClientRect());
			document.addEventListener('mousedown', this.onMouseDown);
			window.addEventListener('scroll', this.onScroll, { capture: true });
			this.placePopup(el);

			// нужно для асинхронного контента
			this.startObserve(el);
		}
		else {
			document.removeEventListener('mousedown', this.onMouseDown);
			window.removeEventListener('scroll', this.onScroll, { capture: true });
		}
	};

	onMouseDown = e => {
		if (this.props.alwaysActive) return;
		// console.log('mouse', this.popup.contains(e.target), this.popup, e.target);
		if (this.state.active && this.popup && this.state.el && !this.popup.contains(e.target) && !this.state.el.contains(e.target)) {
			this.close();
		}
	};

	onScroll = e => {
		if (this.props.alwaysActive) return;
		if (this.state.el && e.target.contains(this.state.el)) {
			this.setState({ active: false });
		}
	};

	placePopup = (el) => {
		let rect = el.getBoundingClientRect();
		const wrapper = this.state.el.getBoundingClientRect();

		if (this.props.matchWidth && rect.width < wrapper.width) {
			el.style.width = wrapper.width + 'px';
			rect = el.getBoundingClientRect();
		}

		// console.log(rect.width, wrapper.width);

		const { offset, position } = this.props;
		const [ type, ...classes ] = POSITIONS[position];

		let x = wrapper.left;
		let y = wrapper.top;

		let node = el.parentNode;
		while (node) {
			x += parseInt(node.scrollLeft || 0);
			y += parseInt(node.scrollTop || 0);
			node = node.parentNode;
		}

		const hor = type === VERTICAL ? classes[1] : classes[0];
		const ver = type === HORIZONTAL ? classes[1] : classes[0];

		let horOffset = type === HORIZONTAL ? offset : 0;
		let verOffset = type === VERTICAL ? offset : 0;

		const horDeltas = {
			[VERTICAL]: { left: 0, center: (wrapper.width - rect.width) / 2, right: (wrapper.width - rect.width) },
			[HORIZONTAL]: { left: -rect.width, center: 0, right: wrapper.width },
		};

		const verDeltas = {
			[VERTICAL]: { top: -rect.height, center: -rect.height / 2, bottom: wrapper.height },
			[HORIZONTAL]: { top: 0, center: (wrapper.height - rect.height) / 2, bottom: (wrapper.height - rect.height) },
		};

		const horDelta = horDeltas[type][hor];
		const verDelta = verDeltas[type][ver];

		// console.log({horDelta, verDelta});

		el.style.left = x + horDelta + (Math.sign(horDelta) * horOffset)  + 'px';
		el.style.top = y + verDelta + (Math.sign(verDelta) * verOffset) + 'px';
	};

	startObserve = (el) => {
		const config = {
			attributes: true,
			childList: true,
			subtree: true,
			characterData: true,
		};

		const observer = new MutationObserver(this.elementObserver);
		observer.observe(el, config);
		// observer.disconnect();
	};

	elementObserver = (mutationsList, observer) => {
		console.log('mutationsList', mutationsList);
		if (this.popup) {
			this.placePopup(this.popup);
		}
	};

	render() {
		const { children, style, className, portalClassName, position, popup } = this.props;
		const { active } = this.state;

		const _className = classNames('sp-trigger', className);
		const _portalClassName = classNames('sp-trigger-popup', portalClassName, POSITIONS[position]);
		// console.log('_portalClassName', _portalClassName, '>', portalClassName);

		return <div className={_className} onClick={this.onClick} onMouseDown={this.onTriggerMouseDown} onMouseEnter={this.onMouseEnter} onMouseLeave={this.onMouseLeave} ref={this.onMount} style={style}>
			{children}
			{active && this.state.el && <Portal getRef={this.onPopupMount} className={_portalClassName}>
				{popup}
			</Portal>}
		</div>;
	}

}
