import React from 'react';
import { observable } from 'mobx';
import { observer } from 'mobx-react';
import { Link } from 'react-router-dom';

import store from 'client/store';

@observer
export default class List extends React.Component {

	@observable records = [];

	constructor(props) {
		super(props);
		this.init();
	}

	init = async () => {
		this.records = await store.model.Magazine.find({
			order: 'countyId asc',
			include: ['county'],
		});
	};

	render() {
		return <div className="magazines">
			<div className="page-title">
				<h1>Журналы</h1>
			</div>
			<div className="magazines-list">
				{this.records.map(record => <Link to={`/magazines/${record.id}`} key={record.id} className="magazine">
					<div className="name">{record.name}</div>
					<div className="county">{record.county ? record.county.name : ''}</div>
				</Link>)}
			</div>
		</div>
	}

}
