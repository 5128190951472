import React from 'react';
import { observable } from 'mobx';
import { observer } from 'mobx-react';
import { Link } from 'react-router-dom';

import CountyMenu from '../CountyMenu';
import searchIcon from 'client/img/search-icon.svg';
import store from 'client/store';
import './style.scss';

@observer
export default class DesktopMenu extends React.Component {
	@observable subMenuActive = false;

	constructor(props) {
		super(props);
	}

	render() {
		const { className } = this.props;

		const items = !store.local.countyId ? (
			// store.ui.menuItems.map(item => <Link key={item.id} to={item.path}>{item.title}</Link>)
			<>
				<div className="link">
					<Link to="/news">Новости</Link>
				</div>
				<div className="parent">
					Спецпроекты
					<div className="submenu">
						{store.ui.specialProjects.map(({ path, name }) => {
							return (
								<Link key={path} to={`/${path}`}>
									{name}
								</Link>
							);
						})}
					</div>
				</div>
				<div className="link">
					<Link to="/counties">Районы</Link>
				</div>
				<div className="link">
					<Link to="/opinion">
						{store.ui.menuItems.find(item => item.path === 'opinion')?.title || 'Мнения/Тус санаа'}
					</Link>
				</div>
				{/* <div className="link"><Link to="/stories">Актуально</Link></div> */}
				<div className="link">
					<Link to="/peoplegames">Игры народов</Link>
				</div>
				<div className="link red">
					<Link to="/partners">Партнерские материалы</Link>
				</div>
				<div className="parent">
					Медиа
					<div className="submenu">
						<Link to="/media">Видео</Link>
						<Link to="/podcasts">Подкасты</Link>
					</div>
				</div>
				<div className="link">
					<Link to="/ads">Реклама</Link>
				</div>
			</>
		) : (
			<CountyMenu />
		);

		return (
			<>
				<div className="desktop-menu-container">
					<div className="desktop-menu">
						<div className="left" />
						<div className="links">{items}</div>
						<div className="right">
							<Link to="/search" className="search-btn">
								<img src={searchIcon} alt="" />
							</Link>
						</div>
					</div>
				</div>
			</>
		);
	}
}
