import React from 'react';
import { observer } from 'mobx-react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faComment, faEye } from '@fortawesome/free-solid-svg-icons';

const Info = ({ record }) => {
	return <>
			{
				record.commentsCount ?
				<div className="comments-count">
					<FontAwesomeIcon icon={faComment} />
					{record.commentsCount}
				</div> :
				''
			}
			{/* <div className="views-count">
				<FontAwesomeIcon icon={faEye} /> &nbsp; {record.viewsCount}
			</div> */}
	</>
};

export default observer(Info);