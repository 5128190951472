import React from 'react';
import { observer } from 'mobx-react';
import { Info } from 'components';
import t from "i18n";
import { relative } from 'client/helpers';
import './style.scss';
import { Link } from 'react-router-dom';
import { JPEG_QUALITY } from 'client/constants';


const OpinionCard = ({ article }) => {

	const img = article.image ?
		<img src={article.downloadFile('image') + `?image=[{"resize":{"width":285}}${JPEG_QUALITY}]`} alt="" />
		:
		<span className="no-image">{t('noImage')}</span>;

	return <Link key={article.id} className="opinion-card" to={`/article/${article.id}`}>
		<div className="image">{img}</div>
		<div className="title">{article.title}</div>
		<div className="info">
			<div className="date">{relative(article.publishAt)}</div>
			<div className="stats">
				<Info record={article} />
			</div>
		</div>
	</Link>;
}
export default observer(OpinionCard)