import React from 'react';
import { Link } from 'react-router-dom';
import { relative } from 'client/helpers';
import t from 'i18n';
import { Info } from '../info';
import { JPEG_QUALITY } from 'client/constants';

export default ({ article, showCategory = false, showCounty = true }) => {
	if (!article) return <div className="mid-article"><div className="image" /></div>;

	return <Link to={`/article/${article.id}`} className="mid-article">
		<div className="county">
			{showCategory && <span style={{ marginRight: 10 }}>{article.categoryName}</span>}
			{showCounty && article.countyName || (article.county ? article.county.name : 'Республика')}
		</div>
		<div className="image">
			{article.image ?
				<img src={article.downloadFile('image') + `?image=[{"resize":{"width":400}}${JPEG_QUALITY}]`} alt="" />
				:
				<span className="no-image">{t('noImage')}</span>
			}
		</div>
		<div className="title">{article.title}</div>
		<div className="info">
			<div className="date">{relative(article.publishAt)}</div>
			<div className="article-info">
				<Info record={article} />
			</div>
		</div>
	</Link>;
}