export default {
	translation: {
		delete: 'Удалить?',
		comment: {
			title: 'Комментарий',
			postTitle: 'Написать комментарий',
			post: 'Отправить',
			confirmDelete: 'Удалить комментарий?',
			emptyAuthor: 'Введите ваше имя',
			emptyText: 'Введите текст (минимум 3 символа)',
		},
		feedback: {
			post: 'Отправить',
			emptyAuthor: 'Введите ваше имя',
			emptyEmail: 'Введите ваш e-mail адрес',
			emptyReason: 'Выберите причину сообщения',
			emptyTitle: 'Введите тему (минимум 3 символа)',
			emptyText: 'Введите текст (минимум 3 символа)',
		},
		banner: {
			title: 'Баннер',
			noImage: 'нет изображения',
		},
		LOGIN_FAILED: 'Неверный логин/пароль',
		LOGIN_WAIT: 'Превышен лимит попыток',
		LOGIN_EMPTY_CAPTCHA: 'Не введен код',
		LOGIN_INVALID_CAPTCHA: 'Неверный код',
		captcha: 'Код на картинке',
		wait: 'Подождите',
		seconds: 'секунд',
		noImage: 'Фото нет',
	},
};
