import React from 'react';
import { observable } from 'mobx';
import { observer } from 'mobx-react';
import { Link } from 'react-router-dom';

import store from 'client/store';
import './style.scss';

@observer
export default class SpecialProjects extends React.Component {
	render() {
		return <div className="special-projects-page">
			{store.ui.specialProjects.map(({ path, name, description }) => <>
				<div key={path} className="link">
					<Link key={path} to={`/${path}`}>{name}</Link>
				</div>
				<span>
					{description}
				</span>
			</>)}
		</div>;
	}

}
