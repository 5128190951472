import React from 'react';
import { Router, Route, Switch, StaticRouter } from 'react-router-dom';
import { observer } from 'mobx-react';
import { InnerPage } from './components';
import * as Pages from './pages';
import store, { isBrowser } from './store';
import '@smartplatform/ui/src/styles/vars.scss';
import './styles/main.scss';
import 'bootstrap/dist/css/bootstrap-grid.css';
import { clipboardCopyrights } from './helpers';

import 'client/img/logo-m.png';
import 'client/img/logo-square.png';

@observer
export default class App extends React.Component {

	constructor(props) {
		super(props);
		store.history.listen(this.onRouteChange);
	}

	onRouteChange = (location, action) => {
		// console.log('onRouteChange', action, location.pathname, location.state);
		if (action === 'PUSH') window.scrollTo(0, 0);
	}

	render() {
		document.oncopy = clipboardCopyrights;
		const _Router = isBrowser ? Router : StaticRouter;

		return (
			<_Router history={store.history} location={this.props.location}>
				{store.isInitialized ? (
					<Switch>
						<Route path="/" exact component={Pages.MainPage} />
						<Route>
							<InnerPage>
								<Route path="/article/:id"><Pages.Article /></Route>
								<Route path="/podcasts"><Pages.Podcasts /></Route>
								<Route path="/magazines"><Pages.Magazines /></Route>
								<Route path="/media"><Pages.Media /></Route>
								<Route path="/counties"><Pages.Counties /></Route>
								<Route path="/memory"><Pages.Memory /></Route>
								<Route path="/events"><Pages.Events /></Route>
								<Route path="/handbook"><Pages.CountyPage path="handbook" /></Route>
								<Route path="/about"><Pages.CountyPage path="about" /></Route>
								<Route path="/ads"><Pages.CountyPage path="ads" /></Route>
								<Route path="/contacts"><Pages.CountyPage path="contacts" /></Route>
								<Route path="/congratulations"><Pages.Congratulations /></Route>
								<Route path="/search"><Pages.Search /></Route>
								<Route path="/feedback"><Pages.Feedback /></Route>
								<Route path="/polls-archive"><Pages.PollsArchive /></Route>
								<Route path="/specialprojects"><Pages.SpecialProjects /></Route>
								<Route path="/label/:label"><Pages.FindLabel/></Route>								
								<Route path="/:path"><Pages.News/></Route>
								{/*	FindPage убран в pages/Newsm<Route><FindPage /></Route> */}			
							</InnerPage>
						</Route>
					</Switch>
				)
					:
					'...' // временно, индикация того что приложение работает, но store.isInitialized = false
				}
			</_Router>
		);
	}
}
