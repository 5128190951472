import React from 'react';
import { Switch, Route } from 'react-router-dom';

import Form from './Form';
import Single from './Single';
import './style.scss';

export default () => (
    <Switch>
        <Route path="/feedback" exact component={Form} />
        <Route path="/feedback/:id" component={Single} />
    </Switch>
);
