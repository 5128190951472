import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import './style.scss';

export default class Button extends React.Component {

	static propTypes = {
		onClick: PropTypes.func,
		className: PropTypes.string,
	};

	constructor(props) {
		super(props);
		this.state = { busy: false };
	}

	onClick = async (e) => {
		if (!this.props.onClick) return;
		this.setState({ busy: true });
		await this.props.onClick(e);
		this.setState({ busy: false });
	};

	render() {
		const { className, onClick, ...rest } = this.props;

		const _className = classNames('um-button', className, {
			busy: this.state.busy
		});

		return <button className={_className} onClick={this.onClick} {...rest} />;
	}

}
