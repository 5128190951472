import React from 'react';
import PropTypes from 'prop-types';
import { observable } from 'mobx';
import { observer } from 'mobx-react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEye } from '@fortawesome/free-solid-svg-icons';

// import { generateKey, encrypt, decrypt, pack, unpack } from './crypto';
import { SECURE } from '.';
import store from 'client/store';
import './style.scss';

const relations = {
	Article: 'articleId',
	ViewArticle: 'articleId',
	Album: 'albumId',
	AlbumView: 'albumId',
	Media: 'mediaId',
	ViewMedia: 'mediaId',
};

@observer
export default class ViewsCountActive extends React.Component {

	static propTypes = {
		record: PropTypes.object.isRequired,
	};

	@observable count = 1;

	constructor(props) {
		super(props);
		this.modelName = props.record.MODEL.INFO.name;
		this.foreignKey = relations[this.modelName];
		this.init();
	}
	componentDidUpdate(prevProps, prevState, snapshot) {
		if (prevProps.index !== this.props.index) {
			this.init()
		}
	}

	init = async () => {
		const payload = {
			id: store.visitorId,
			[this.foreignKey]: this.props.record.id,
		};

		if (SECURE) {
/*
			const msg = JSON.stringify(payload);
			const key = await generateKey();
			const exported = await crypto.subtle.exportKey('raw', key);
			const { cipher, iv } = await encrypt(msg, key);
			const body = {
				cipher: pack(cipher),
				iv: pack(iv),
				key: pack(exported),
				active: true,
				secure: SECURE
			};
			this.count = await store.model.View.getCount(body);
*/
		}
		else {
			this.count = await store.model.View.getCount({
				...payload,
				active: true,
				secure: SECURE
			});
		}
	};

	render() {
		return <div className="views-count">
			{/* временно скрыты */}
			{/* <FontAwesomeIcon icon={faEye} /> {this.count} */}
		</div>;
	}

}
