import React from 'react';
import { observable } from 'mobx';
import { observer } from 'mobx-react';
import MediaQuery from 'react-responsive';

import { BigArticle, MidArticle } from 'components';
import store from 'client/store';

@observer
export default class News extends React.Component {

	@observable records = [];
	@observable isLoading = true;

	constructor(props) {
		super(props);
		this.init();
	}

	init = async () => {
		this.records = await store.model.ViewLabel.getArticles({
			exclude: [ this.props.currentArticle.id ],
			category: 1,
			willBePublished: true,
			showOnMain: !store.local.countyId,
			fields: ['id', 'image', 'title', 'publishAt',
				'commentsCount', 'viewsCount', 'countyName'],
			limit: 7,
			skip: 0,
			// showOnMain: this.props.currentArticle.countyId ? false : undefined,
			order: 'publishAt desc',
		});
/*
		this.records = await store.model.ViewArticle.find({
			where: {
				category: 1,
				id: { neq: this.props.currentArticle.id },
				image: { neq: null },
				publish: true,
			},
			fields: ['id', 'title', 'subtitle', 'image', 'countyId', 'countyName', 'publishAt'],
			include: [
				{ relation: 'county', scope: { fields: ['id', 'name'] }},
			],
			limit: 7,
			order: 'publishAt desc',
		});
*/
		this.isLoading = false;
	};

	render() {
		if (this.isLoading) {
			return <div className="news-more">...</div>
		}

		return <div className="news-more">
			<div className="page-title">
				<h1>Также на UlusMedia</h1>
			</div>
			<div className="articles">
				{this.records.map((article, i) => {
					if (i === 0) {
						return <React.Fragment key={article.id}>
							<MediaQuery minWidth={768}><BigArticle article={article} /></MediaQuery>
							<MediaQuery maxWidth={767}><MidArticle article={article} /></MediaQuery>
						</React.Fragment>;
					}
					return <MidArticle key={article.id} article={article} />
				})}
			</div>
		</div>;
	}

}
