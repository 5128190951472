import React from 'react';
import { observable } from 'mobx';
import { observer } from 'mobx-react';
import { withRouter } from 'react-router-dom';
import { Button, Breadcrumbs } from 'components';
import store from 'client/store';
import Articles from 'components/news/Articles';
import { PageNotFound } from '../404';

const INIT_COUNT = 18;
const MORE_COUNT = 8;

@withRouter @observer
export default class FindLabel extends React.Component {
	// компонент показывает все статьи по метке в роуте

	@observable records = [];
	@observable isLoading = true;
	@observable totalCount = 0;
	@observable label = null;
	@observable error = null;

	constructor(props) {
		super(props);
		this.init();
	}
	componentDidUpdate(prevProps, prevState, snapshot) {
		if (prevProps.countyId !== this.props.countyId
			|| prevProps.match.params.label !== this.props.match.params.label) {
			this.init();
		}
	}


	init = async () => {
		try {
			this.isLoading = true;
			const label = await store.model.ViewLabel.find({
				where: {
					name: { ilike: this.props.match.params.label }
				}
			});
			this.label = label.length > 0 ? label[0] : null;

			const params = {
				willBePublished: true,
				labels: this.label ? [this.label.id] : [],
				// county: store.local.countyId,
				showOnMain: !store.local.countyId,
				fields: ['id', 'image', 'title', 'publishAt', 'commentsCount', 'viewsCount', 'countyName', 'categoryId'],
				limit: INIT_COUNT,
				skip: 0,
				order: 'publishAt desc',
			};

			if (this.label) this.records = await store.model.ViewLabel.getArticles(params);
			this.totalCount = this.records[0] ? this.records[0].totalCount : 0;
		} catch (e) {
			this.error = e;
		}
		this.isLoading = false;
	};

	loadMore = async () => {
		const records = await store.model.ViewLabel.getArticles({
			willBePublished: true,
			labels: this.label ? [this.label.id] : [],
			county: store.local.countyId,
			showOnMain: !store.local.countyId,
			fields: ['id', 'image', 'title', 'publishAt', 'commentsCount', 'viewsCount', 'countyName'],
			limit: this.records.length === 0 ? INIT_COUNT : MORE_COUNT,
			skip: this.records.length,
			order: 'publishAt desc',
		});

		this.records.push(...records);
		this.totalCount = records[0] ? records[0].totalCount : 0;
	};

	render() {
		if (this.isLoading) {
			return null;
		}
		const label = this.props.match.params.label;
		if (!this.label || this.error) return <PageNotFound />

		return <div className="news-page">
			<div className="news-lenta">
				<Breadcrumbs crumbs={[{
					title: label,
					path: "/label/" + label
				}]} />
				<div className="page-title">
					<h1> {label} </h1>
				</div>

				<Articles articles={this.records} />
				<div className="load-more">
					<Button onClick={this.loadMore} disabled={this.records.length === this.totalCount}>Показать еще</Button>
				</div>
			</div>
		</div>;
	}

}
