import qs from 'qs';
import { createBrowserHistory, createMemoryHistory  } from 'history';
import { observable } from 'mobx';
import { Rest, ModelStore } from '@smartplatform/model-store';
import { RouteStore } from '@smartplatform/route-store';
import { LocalStore } from '@smartplatform/local-store';
import UIStore from './uiStore';
import FingerprintJS from '@fingerprintjs/fingerprintjs';
import punycode from 'punycode';

export const isBrowser = typeof window !== 'undefined';

/**
 * Глобальный контейнер сторов
 */
export class AppStore {

	@observable isInitialized = false;

	local; // Локальное хранилище
	model; // Хранилище моделей
	route; // Хранилище маршрута браузера
	api; // API обмена с сервером
	admin; // Хранилище @admin
	ui; // ui
	cache;

	constructor() {
		this.history = isBrowser ? createBrowserHistory() : createMemoryHistory();
		this.ui = new UIStore(this);
		this.cache = {};

		this.local = new LocalStore();
		this.local.extend({
			countyId: null,
		});
		this.local.save();

		this.rest = new Rest();
		this.model = new ModelStore({
			transport: this.rest,
			cache: false,
			autoConnect: false,
		});

		this.route = new RouteStore({
			history: this.history,
			decode: qs.parse,
			encode: qs.stringify,
		});
	}

	init = async () => {
		this.domain = punycode.toUnicode(location.hostname);
		console.log('domain', this.domain);
		await this.initFingerprint();
		await this.model.connect();
		await this.ui.init();
		this.patchDownloadFile('ViewAlbum', 'Album');
		this.patchDownloadFile('ViewMedia', 'Media');
		this.patchDownloadFile('ViewArticle', 'Article');
		this.patchDownloadFile('ViewArticlePlace', 'Article', 'articleId');
		this.isInitialized = true;
	};

	initFingerprint = async () => {
		const fp = await FingerprintJS.load();
		const result = await fp.get();
		this.visitorId = result.visitorId;
		console.log('visitorId', this.visitorId);
		this.setVisitorCookie();
	};

	patchDownloadFile = (viewName, modelName, id = 'id') => {
		const modelStore = this.model;
		if (modelStore[viewName] && modelStore[modelName]) {
			modelStore[viewName].prototype.downloadFile = function (property) {
				return modelStore.downloadFile(modelStore[modelName], this[id], property);
			}
		}
	};

	setVisitorCookie = () => {
		document.cookie = `_vid=${this.visitorId};path=/;max-age=31536000`;
	};
}

const appStore = new AppStore()
if (isBrowser) window.APP_STORE = appStore;

export default appStore;
