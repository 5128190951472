import React from 'react';
import { observable } from 'mobx';
import { observer } from 'mobx-react';
import { Link } from 'react-router-dom';
import { formatDate } from 'client/helpers';
import { Info } from '../info';
import { JPEG_QUALITY } from 'client/constants';

@observer
export default class MemoryCard extends React.Component {

	@observable popup = false;

	constructor(props) {
		super(props);
	}

	render() {
		const { record } = this.props;

		return <Link to={`/article/${record.id}`} className="memory-card">
			<div className="photo">
				<img src={record.downloadFile('image') + `?image=[{"resize":{"width":285}}${JPEG_QUALITY}]`} alt='' />
			</div>
			<div className='fio'>{record.title}</div>
			<div className='dates'>
				{record.date0 ? formatDate(record.date0) : ''}
				{record.date0 && record.date1 ? ' - ' : ''}
				{record.date1 ? formatDate(record.date1) : ''}
			</div>
			<div className='info'>
				<Info record={record} />
			</div>
		</Link>;
	}
}
