import React from 'react';
import { observable } from 'mobx';
import { observer } from 'mobx-react';
import { withRouter, Link, Route } from 'react-router-dom';

import { HtmlValue } from '@smartplatform/ui';
import { Tabs, Tab } from '../route-tabs';
import { Menu, MenuItem } from '../route-menu';
import { Links, LinkItem } from '../route-links';
import store from '../../store';

import './style.scss';

@withRouter @observer
export default class PageTree extends React.Component {

	@observable tree = null;
	@observable error = null;
	
	constructor(props) {
		super(props);
	}
	
	getBreadCrumbs = page => {
		let breadcrumbs = [{ name: page.title, link: page.route }];
		
		const childrenBreadcrumbs = page => {
			page._children.filter(child => !!child._active).forEach(child => {
				breadcrumbs.push({ name: child.title, link: child.route });
				childrenBreadcrumbs(child);
			});
		};
		childrenBreadcrumbs(page);
		return breadcrumbs;
	};
	
	renderNode = node => {
		let content;
		let pageContent = node.text ? <HtmlValue className="editor-text" value={node.text} /> : null;
		let hideNav = false;

		if (node._nav) {
			let Parent = Tabs;
			let Item = Tab;
			
			if (node._nav === 'menu') {
				Parent = Menu;
				Item = MenuItem;
			}
			
			if (node._nav === 'links') {
				Parent = Links;
				Item = LinkItem;
				if (new RegExp(`^${node.route}\/`, '').test(store.route.path)) {
					console.log('>>>', node.route, store.route.path);
					hideNav = true;
				}
				console.log('?', node.route, store.route.path, hideNav);
			}

			content = <>
				{node.showText === 'above' && !hideNav && pageContent}
				<Parent className={'static-page-nav' + (hideNav ? ' hide-nav' : '')} linksOnly>
					{node._children.map((page, i) => <Item key={page.id} path={page.route} title={page.title}/>)}
					{node._children.filter(child => !!child._active).map(child => <div key={child.id}>{this.renderNode(child)}</div>)}
				</Parent>
				{node.showText === 'below' && !hideNav && pageContent}
			</>;
		}
		else {
			const hideParent = new RegExp(`^${node.route}\/`, '').test(store.route.path);
			content = <>
				{!hideParent ?
					<>
						<div className="page-title"><h1>{node.title}</h1></div>
						{pageContent}
					</>
					:
					null
				}
				{node._children.map((page, i) => <Route key={page.id} path={page.route} render={() => <>
					<div className="page-title"><h1>{node.title}</h1></div>
					<HtmlValue className="editor-text" value={page.text} />
				</>}/>)}
			</>;
		}
		return content;
	};
	
	render() {
		const page = this.props.tree;
		const content = this.renderNode(page);

		// const breadcrumbs = this.getBreadCrumbs(page);
		// store.ui.setBreadcrumbs(breadcrumbs);
		
		return <div ref={this.props.onMount}>
			{content}
		</div>;
	}
	
}
