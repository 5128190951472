import React from 'react';
import PropTypes from 'prop-types';
import { observer } from 'mobx-react';
import { observable } from 'mobx';
import { ViewsCountActive } from '../';
import { SlideInput } from '@smartplatform/ui';
import './style.scss';
import iconRewindPlus from 'client/img/rewind-plus.svg';
import iconPlayPrev from 'client/img/play-prev.svg';
import iconRewindMinus from 'client/img/rewind-minus.svg';
import pauseBtn from 'client/img/icon-pause.svg';
import iconPlay from 'client/img/icon-play.svg';
import iconPlayNext from 'client/img/play-next.svg';

const podcastSpeeds = ['1', '1,25', '1,5', '1,75', '2'];

@observer
export default class PodcastPlayer extends React.Component {
	static propTypes = {
		podcast: PropTypes.object.isRequired,
		single: PropTypes.bool,
		onPrev: PropTypes.func, // callback для перехода на предыдущий подкаст
		onNext: PropTypes.func, // callback для перехода на следующий подкаст
	};

	static defaultProps = {
		single: false,
	};

	@observable audio = null;
	@observable isPlaying = false;
	@observable volume = 15;
	@observable startPinCoord = 0;
	@observable currentPodcastIndex = 0;
	@observable currentSpeedIndex = 0;

	@observable currentTime = 0;
	@observable duration = 0;
	@observable gotInfo = false;
	@observable error = null;
	@observable isPlayed = false;

	onAudioMount = el => {
		this.audio = el;
		if (this.audio) {
			this.audio.volume = this.volume / 100;
		}
	};

	formatTime = seconds => {
		const from = seconds > 3600 ? 11 : 14;
		const length = seconds > 3600 ? 8 : 5;
		return new Date(seconds * 1000).toISOString().substr(from, length);
	};

	setDuration = () => {
		if (this.audio && this.audio.duration !== Infinity) {
			this.duration = this.audio.duration;
			this.gotInfo = true;
		}
	};

	play = async () => {
		if (this.audio) {
			try {
				this.isPlaying ? await this.audio.pause() : await this.audio.play();
				this.isPlaying = !this.isPlaying;
				this.isPlayed = true;
			} catch (e) {
				this.error = 'Файл был удален';
			}
		}
	};

	rewindTime = async el => {
		if (this.audio) {
			el.currentTarget.src.match(iconRewindPlus)
				? (this.audio.currentTime += 15)
				: (this.audio.currentTime -= 15);
		}
	};

	onTimeUpdate = () => {
		if (this.audio) {
			this.currentTime = this.audio.currentTime;
		}
	};

	setPodcast = isDecrement => {
		if (isDecrement) {
			this.currentPodcastIndex--;
		} else {
			this.currentPodcastIndex++;
		}
		this.currentSpeedIndex = 0;
		this.audio.currentTime = 0;
		this.currentTime = 0;
		this.isPlaying = false;
	};

	setPodcastSpeed = () => {
		this.currentSpeedIndex === 4 ? (this.currentSpeedIndex = 0) : this.currentSpeedIndex++;
		switch (this.currentSpeedIndex) {
			case 0:
				this.audio.playbackRate = 1.0;
				break;
			case 1:
				this.audio.playbackRate = 1.25;
				break;
			case 2:
				this.audio.playbackRate = 1.5;
				break;
			case 3:
				this.audio.playbackRate = 1.75;
				break;
			case 4:
				this.audio.playbackRate = 2.0;
				break;
		}
	};

	onSeek = value => {
		if (this.audio) {
			this.audio.currentTime = this.currentTime = value;
			if (this.isPlaying) {
				this.audio.pause();
			}
		}
	};

	onSeekEnd = value => {
		if (this.audio) {
			this.audio.currentTime = this.currentTime = value;
			if (this.isPlaying) this.audio.play();
		}
	};

	onVolumeChange = value => {
		this.volume = value;
		if (this.audio) {
			this.audio.volume = this.volume / 100;
		}
	};

	render() {
		const { podcast, onPrev, onNext, single } = this.props;

		return (
			<div className={'podcast-player' + (single ? ' single' : '')}>
				<div className="image">
					<img src={podcast.downloadFile('image')} alt="" />
				</div>
				<div className="content">
					<div className="podcast-title">{podcast.name}</div>
					{this.error && <div className="podcast-error">{this.error}</div>}
					<div className="podcast-controls">
						<div className="timeline">
							<SlideInput
								min={0}
								max={this.duration}
								value={this.currentTime}
								onChange={this.onSeek}
								onChangeEnd={this.onSeekEnd}
								disabled={!this.gotInfo}
								className="status-line"
							/>
							<span className="current-time">{this.formatTime(this.currentTime)}</span>
							<span className="rest-time">{this.gotInfo ? this.formatTime(this.duration) : '...'}</span>
						</div>
						<div className="buttons">
							{!onPrev ? (
								<div style={{ width: '41px' }} />
							) : (
								<img onClick={onPrev} src={iconPlayPrev} alt="" />
							)}
							<img onClick={this.rewindTime} src={iconRewindMinus} alt="" />
							<img onClick={this.play} src={this.isPlaying ? pauseBtn : iconPlay} alt="" />
							<img onClick={this.rewindTime} src={iconRewindPlus} alt="" />
							{!onNext ? (
								<div style={{ width: '41px' }} />
							) : (
								<img onClick={onNext} src={iconPlayNext} alt="" />
							)}
							<span onClick={this.setPodcastSpeed} className="button-speed">
								{podcastSpeeds[this.currentSpeedIndex]}x
							</span>
						</div>

						<div className="volume">
							<SlideInput min={0} max={100} value={this.volume} onChange={this.onVolumeChange} />
							<span className="percent">{this.volume}%</span>
						</div>
					</div>
				</div>
				<audio
					src={podcast.downloadFile('audioFile')}
					ref={this.onAudioMount}
					onTimeUpdate={this.onTimeUpdate}
					onLoadedMetadata={this.setDuration}
					onEnded={() => (this.isPlaying = false)}
				/>
				{/* активация счетчика просмотров */}
				<div className="active-count">{this.isPlayed && <ViewsCountActive record={podcast} />}</div>
			</div>
		);
	}
}
