
import React from 'react';
import { observer } from 'mobx-react';
import { Info } from 'components';
import { relative } from 'client/helpers';
import './style.scss';
import { Link } from 'react-router-dom';
import { JPEG_QUALITY } from 'client/constants';

const CategoryCard = ({ record, promo }) => {

	return <Link to={`/article/${promo ? record.articleId : record.id}`} className="category-card" >
		<div className="header">{record.categoryName}</div>
		{promo && <div className='topic-block'>
			<Topic record={record} />
		</div>}
		<div className="image"><img src={record.downloadFile('image') + `?image=[{"resize":{"width":285}}${JPEG_QUALITY}]`} alt="" /></div>
		<div className="title">{record.title}</div>
		<div className="info">
			<div className="date">{relative(record.publishAt)}</div>
			<div className="stats"><Info record={record} /></div>
		</div>
	</Link >
}
const Topic = ({ record }) => {
	return record.topics().length ? record.topics().map((topic, i) => {
		return <div key={i} className='topic'> {topic.name} </div>
	}) : null
}

export default observer(CategoryCard)