import React from 'react';
import { Link } from 'react-router-dom';

import { relative } from 'client/helpers';
import { Info } from '../info';

export default ({ article }) => {
	if (!article) return <div className="small-article"/>;

	return <Link to={`/article/${article.id}`} className="small-article">
		<div className="title">{article.title}</div>
		<div className="county">{article.countyName || (article.county ? article.county.name : 'Республика')}</div>
		<div className="info">
			<div className="date">{relative(article.publishAt)}</div>
			<div className="article-info">
			<Info record={article} />
			</div>
		</div>
	</Link>;
}