import React from 'react';
import { observable } from 'mobx';
import { observer } from 'mobx-react';
import { Link } from 'react-router-dom';

import { Pager } from '@smartplatform/ui';
import { formatDate } from 'client/helpers';
import store from 'client/store';

const PER_PAGE = 10;

@observer
export default class List extends React.Component {

	@observable records = [];
	@observable page = 1;

	constructor(props) {
		super(props);
		this.init();
	}

	init = async () => {
		const where = {
			and: [
				{ categoryId: 4 },
				{ image: { neq: null } },
				{ publish: true },
				{ countyId: store.local.countyId || 1 }
			]
		};

		this.records = await store.model.Article.find({
			where,
			include: ['county'],
			order: 'id desc',
			limit: PER_PAGE,
			skip: (this.page - 1) * PER_PAGE,
		});
	};

	onPageChange = page => {
		this.page = page;
		this.init();
	};

	render() {
		return <div className="events-page">
			<div className="page-title">
				<h1>Афиша</h1>
			</div>
			<div className="events-list">
				{this.records.map(record => <Link to={`/events/${record.id}`} key={record.id} className="event-item">
					<div className="image"><img src={record.downloadFile('image') + '?image=[{"resize":{"width":400}}]'} alt="" /></div>
					<div className="info">
						<div className="name">{record.title}</div>
						<div className="dates">
							{record.date0 ? formatDate(record.date0) : ''}
							{record.date0 && record.date1 ? ' - ' : ''}
							{record.date1 ? formatDate(record.date1) : ''}
						</div>
						<div className="place">{record.subtitle}</div>
						<div className="text" dangerouslySetInnerHTML={{ __html: record.text }} />
					</div>
				</Link>)}
			</div>
			<Pager current={this.page} itemsPerPage={PER_PAGE} totalCount={this.records.totalCount} onChange={this.onPageChange} />
		</div>
	}

}
