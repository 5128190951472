import React from 'react';
import PropTypes from 'prop-types';
import { observable } from 'mobx';
import { observer } from 'mobx-react';

import { Trigger, TRIGGER_MANUAL } from '../trigger';
import './style.scss';

@observer
export default class SuggestInput extends React.Component {

	static propTypes = {
		suggestions: PropTypes.object,
		onFetchSuggestions: PropTypes.func,
		renderSuggestion: PropTypes.func,
		toString: PropTypes.func,
		onSubmit: PropTypes.func,
		onRawSubmit: PropTypes.func,
		placeholder: PropTypes.string,
		clearOnSubmit: PropTypes.bool,
		inputRef: PropTypes.func,
	};

	static defaultProps = {
		suggestions: [],
		clearOnSubmit: false,
		renderSuggestion: value => value,
		toString: value => value.toString(),
	};

	stopOnBlur = false;
	refs = [];

	constructor(props) {
		super(props);
		this.state = {
			value: '',
			currentSuggestion: null,
		};
	}

	componentDidUpdate(prevProps, prevState, snapshot) {
		if (this.props.suggestions.length === 0) {
			this.close();
		}
	}

	getTriggerInstance = instance => this.triggerInstance = instance;

	onChange = (e) => {
		this.setState({ value: e.target.value });
		this.getSuggestions(e.target.value);
	};

	getSuggestions = async (value) => {
		if (this.props.onFetchSuggestions) {
			await this.props.onFetchSuggestions(value.trim());
			if (this.props.suggestions.length > 0 && this.triggerInstance && !this.triggerInstance.isActive()) {
				this.triggerInstance.activate();
			}
		}
	};

	onSelect = (value) => {
		this.setState({ value: this.props.clearOnSubmit ? '' : this.props.toString(value) });
		if (this.props.onSubmit) this.props.onSubmit(value);
		this.close();
	};

	onInputMount = el => {
		this.input = el;
		this.props.inputRef && this.props.inputRef(el);
	};

	onFocus = () => {
		if ((this.props.suggestions.length > 0) && this.triggerInstance && this.state.value.trim().length > 0) this.triggerInstance.activate();
	};

	onBlur = () => {
		if (!this.stopOnBlur) this.close();
		this.stopOnBlur = false;
	};

	close = () => {
		this.currentSuggestion = null;
		this.triggerInstance && this.triggerInstance.close();
	};

	onKeyDown = async (e) => {
		const keyCode = e.keyCode;
		if (keyCode === 13) {
			if (this.currentSuggestion !== null && this.props.suggestions[this.currentSuggestion]) {
				if (this.props.clearOnSubmit) this.setState({ value: '' });
				if (this.props.onSubmit) this.props.onSubmit(this.props.suggestions[this.currentSuggestion]);
			}
			else {
				if (this.input) this.input.blur();
				const trimmed = this.state.value.trim();
				if (trimmed.length > 0) {
					if (this.props.onRawSubmit) {
						await this.props.onRawSubmit(trimmed);
						if (this.props.clearOnSubmit) this.setState({ value: '' });
					}
				}
				else {
					this.setState({ value: '' });
				}
			}
			this.close();
		}
		else if (keyCode === 40 && this.props.suggestions.length > 0) {
			e.preventDefault();
			if (this.currentSuggestion === null) {
				this.currentSuggestion = 0;
			}
			else {
				this.deselect(this.currentSuggestion);
				if (this.currentSuggestion < this.props.suggestions.length - 1) {
					this.currentSuggestion++;
				}
				else {
					this.currentSuggestion = 0;
				}
			}
			this.hilight(this.currentSuggestion);
		}
		else if (keyCode === 38 && this.props.suggestions.length > 0) {
			e.preventDefault();
			if (this.currentSuggestion === null) {
				this.currentSuggestion = this.props.suggestions.length - 1;
			}
			else {
				this.deselect(this.currentSuggestion);
				if (this.currentSuggestion > 0) {
					this.currentSuggestion--;
				}
				else {
					this.currentSuggestion = this.props.suggestions.length - 1;
				}
			}
			this.hilight(this.currentSuggestion);
		}
	};

	hilight = index => {
		if (this.refs[index] && this.refs[index].current) {
			this.refs[index].current.classList.add('current');
		}
	};

	deselect = index => {
		if (this.refs[index] && this.refs[index].current) {
			this.refs[index].current.classList.remove('current');
		}
	};

	onMouseDown = () => {
		this.stopOnBlur = true;
	};

	render() {
		const { suggestions, renderSuggestion, placeholder } = this.props;

		this.refs = [];

		const popup = (suggestions.length > 0) && <div className="suggestions">
			{suggestions.map((value, i) => {
				this.refs[i] = React.createRef();
				return <div
					key={i}
					ref={this.refs[i]}
					className="suggestion"
					onMouseDown={this.onMouseDown}
					onClick={() => this.onSelect(value)}>
					{renderSuggestion(value)}
				</div>;
			})}
		</div>;

		return (
			<Trigger
				className="suggest-input"
				popup={popup}
				getInstance={this.getTriggerInstance}
				trigger={TRIGGER_MANUAL}
				position="bottomLeft"
				portalClassName="suggestions-popup">
				<input
					type="text"
					value={this.state.value}
					onChange={this.onChange}
					onKeyDown={this.onKeyDown}
					onFocus={this.onFocus}
					onBlur={this.onBlur}
					ref={this.onInputMount}
					placeholder={placeholder}
				/>
			</Trigger>
		);
	}

}
