import React from 'react';
import { observable } from 'mobx';
import { Link } from 'react-router-dom';
import { observer } from 'mobx-react';
import store from '../../store';

@observer
export default class Single extends React.Component {

	@observable record = null;
	@observable isLoading = true;

	constructor(props) {
		super(props);
		this.init();
	}

	init = async () => {
		this.record = await store.model.Feedback.findById(this.props.match.params.id);
		this.isLoading = false;
	};

	render() {
	    console.log(this.record)
		return <div className="feedback-form">
                <div className="page-title">
                    <h1>Обратная связь</h1>
                </div>

			{this.isLoading ?
				''
				:
				(this.record ? <>
						{/*<p>Ваше обращение успешно отправлено.</p>*/}
						<p>Ваше заявка была успешно зарегистрирована.<br />Номер вашей заявки: № <strong>{this.record.id}</strong></p>
						<p><Link to="/">Вернуться на главную</Link></p>
					</>
					:
					'Заявка не найдена'
				)
			}
		</div>;
	}
}
