import React from 'react';
import PropTypes from 'prop-types';
import { observable, toJS } from 'mobx';
import { observer } from 'mobx-react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTimes } from '@fortawesome/free-solid-svg-icons';

import { SuggestInput } from 'components';
import store from 'client/store';
import './style.scss';

const PER_PAGE = 10;

@observer
export default class Labels extends React.Component {

	static propTypes = {
		placeholder: PropTypes.string,
		onChange: PropTypes.func,
	};

	static defaultProps = {
		placeholder: 'Название тега',
		model: 'ViewArticleLabel'
	};

	@observable labels = [];
	@observable suggestions = [];

	refs = [];

	constructor(props) {
		super(props);
	}

	componentDidUpdate() {
		if (this.input) this.input.focus();
	}

	inputRef = el => this.input = el;

	onFetchSuggestions = async (value) => {
		if (value.length === 0) {
			this.suggestions = [];
		}
		else {
			this.suggestions = await store.model[this.props.model].getFiltered({
				mainPriority: false,
				search: value,
				categoryId: this.props.categoryId,
				countyId: store.local.countyId,
				type: this.props.type,
				showOnMain: store.local.countyId ? null : true,
				name: { ilike: `${value}%` },
				order: 'articleCount desc,name asc',
				limit: PER_PAGE
			});
		}
	};

	renderSuggestion = label => <div className='suggestion-portal'>
		<span>{label.name}</span>
		<span>{label.articlecount}</span>
	</div>;

	onSubmit = (label) => {
		this.addLabel(label);
		this.suggestions = [];
	};

	addLabel = (label) => {
		const existing = this.labels.find(l => label.id === l.id);
		if (existing) {
			this.hilightAlreadyAdded(label);
		}
		else {
			this.labels.push(label);
		}
		this.props.onChange && this.props.onChange(this.labels);
	};

	removeLabel = (label) => {
		const index = this.labels.findIndex(l => label.id === l.id);
		if (index !== -1) {
			this.labels.splice(index, 1);
			this.props.onChange && this.props.onChange(this.labels);
		}
		return;
	};

	hilightAlreadyAdded = (label) => {
		if (this.refs[label.id] && this.refs[label.id].current) {
			const el = this.refs[label.id].current;
			requestAnimationFrame(() => {
				el.classList.add('existing-in');
				requestAnimationFrame(() => {
					el.classList.remove('existing-in');
					el.classList.add('existing-out');
					setTimeout(() => {
						el.classList.remove('existing-out');
					}, 2000);
				});
			});
		}
	};

	render() {
		this.refs = [];

		return <div className="labels-edit">
			{this.labels.map(label => {
				this.refs[label.id] = React.createRef();
				return <span key={label.id} className="label-item" ref={this.refs[label.id]}>
					{label.name}
					<span className="remove" onClick={() => this.removeLabel(label)}><FontAwesomeIcon icon={faTimes} /></span>
				</span>
			})}

			<SuggestInput
				suggestions={this.suggestions}
				placeholder={this.props.placeholder}
				renderSuggestion={this.renderSuggestion}
				toString={this.renderSuggestion}
				onFetchSuggestions={this.onFetchSuggestions}
				onSubmit={this.onSubmit}
				inputRef={this.inputRef}
				clearOnSubmit
			/>
		</div>;
	}

}
