import React from 'react';
import { observable } from 'mobx';
import { observer } from 'mobx-react';
import { MediaCard, Button, Breadcrumbs } from 'components';
import icon from 'client/img/media-icon.svg';
import store from 'client/store';
import Labels from '../../components/label/Labels';
import { withRouter } from 'react-router-dom';

const INIT_COUNT = 12;
const MORE_COUNT = 8;
@withRouter @observer
export default class Media extends React.Component {
	@observable records = [];
	@observable label = null;
	@observable isLoading = true;
	@observable totalCount = 0;

	constructor(props) {
		super(props);
		this.init();
	}


	init = async () => {

		//если метка в роуте найдена , то ставим её в selected. или уходит на базовый роут при неизвестной метке
		if (this.props.tag && this.isLoading) {
			const label = await store.model.ViewLabel.find({
				where: {
					name: { ilike: this.props.match.params.tag }
				}
			});
			this.label = label.length > 0 ? label[0] : this.props.history.push("/media");
		};

		const params = {
			type: 'video',
			labels: this.label ? [this.label.id] : [],
			county: store.local.countyId,
			showOnMain: !store.local.countyId,
			limit: INIT_COUNT,
			skip: 0,
		};
		this.records = await store.model.ViewAlbum.getAlbums(params);
		this.totalCount = this.records[0] ? this.records[0].totalCount : 0;
		this.isLoading = false;
	};

	loadMore = async () => {
		const records = await store.model.ViewAlbum.getAlbums({
			type: 'video',
			labels: this.label ? [this.label.id] : [],
			county: store.local.countyId,
			showOnMain: !store.local.countyId,
			limit: this.records.length === 0 ? INIT_COUNT : MORE_COUNT,
			skip: this.records.length,
			fields: ['image', 'commentsCount', 'viewsCount', 'id', 'type', 'name']
		});
		this.records.push(...records);
		this.totalCount = records[0] ? records[0].totalCount : 0;
	};


	onLabelSelect = label => {
		if (this.label && this.label.id === label.id) {
			this.label = null;
		}
		else {
			this.label = label;
		}
		this.init();
	};

	render() {
		// const sizes = [1, 0, 0, 0, 1, 1];

		if (this.isLoading) {
			return <div className="news-lenta">...</div>
		}

		return <div className="media-list">
			<Breadcrumbs crumbs={[{
				title: "Видео",
				path: "/media"
			}]} />

			<Labels type='video' relation='ViewAlbum' tagInRoute={this.props.match.params.tag}
				selected={this.label} onSelect={this.onLabelSelect} />

			<h2 className="main"><img src={icon} alt="" /> Видео</h2>
			<div className="cards">
				{this.records.map((item, i) => <MediaCard key={i} media={item} />)}
				{/* {this.records.map((item, i) => <MediaCard key={i} media={item} size={sizes[i] ? 'big' : 'small'} />)} */}
			</div>
			<div className="load-more">
				<Button onClick={this.loadMore} disabled={this.records.length === this.totalCount}>Показать еще</Button>
			</div>
		</div>;
	}
}
