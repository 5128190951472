import React from "react";
import { observable } from "mobx";
import { observer } from "mobx-react";
import { Link, withRouter } from "react-router-dom";

import { Comments, ViewsCountActive } from 'components';
import Photos from './Photos';
import Video from './Video';
import { TYPE_AUDIO, TYPE_VIDEO, TYPE_PHOTO } from "./Media";
import store from "../../store";
import Social from "./Social";
import Labels from '../../components/label/Labels';
import { PageNotFound } from '../404';


@withRouter @observer
export default class Single extends React.Component {

	@observable record = null;
	@observable error = null;

	constructor(props) {
		super(props);
		this.init();
	}

	init = async () => {
		this.id = parseInt(this.props.match.params.id);
		this.error = null;
		if (this.id) {
			try {
				this.record = await store.model.Album.findById(this.id);
			} catch (e) {
				this.error = e.message;
			}
		}
		else {
			this.error = "Некорректный ID";
		}
	};

	render() {
		if (this.error) return <PageNotFound />
		if (!this.record) return "...";

		return <div className="single-album">
			<div className='header'>
				<div className="name">{this.record.name}</div>
				<div><ViewsCountActive record={this.record} /></div>
			</div>
			<div className="description" dangerouslySetInnerHTML={{ __html: this.record.description }} />
			<div className="album-media">
				{this.record.type === TYPE_PHOTO ?
					<Photos key={this.record.id} album={this.record} />
					:
					<Video key={this.record.id} album={this.record} />
				}
			</div>
			<Labels type={this.record.type} recordId={this.record.id} relation='ViewAlbum' categoryPath='media/tag'/>
	
			<div className="footer">
				{this.record.author && <div className="author">{this.record.author}</div>}
				<div className="social-bottom"><Social title={this.record.name} /></div>
			</div>

			<Comments key={this.record.id} record={this.record} foreignKey="albumId" />
		</div>;
	}
}
