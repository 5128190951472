import React from 'react';
import PropTypes from 'prop-types';
import { observable } from 'mobx';
import { observer } from 'mobx-react';
import { withRouter } from 'react-router-dom';
import {  Button, Breadcrumbs } from 'components';
import store from 'client/store';
import './style.scss';
import { Labels } from '../../components';
import Topics from '../../components/topic/Topics';
import Articles from 'components/news/Articles';

const INIT_COUNT = 18;
const MORE_COUNT = 8;

@withRouter @observer
export default class News extends React.Component {

	static propTypes = {
		path: PropTypes.string,
		magazineId: PropTypes.number,
		countyId: PropTypes.number,
		noBanner: PropTypes.bool,
	};

	static defaultProps = {
		noBanner: false,
	};

	@observable source = null;
	@observable records = [];
	@observable label = null;
	@observable topic = null;
	@observable isLoading = true;
	@observable totalCount = 0;
	@observable labelInRoute = null;

	constructor(props) {
		super(props);
		this.init();
	}
	componentDidUpdate(prevProps, prevState, snapshot) {
		if (prevProps.category.id !== this.props.category.id ||
			prevProps.magazineId !== this.props.magazineId ||
			prevProps.countyId !== this.props.countyId) {
			this.init();
		}
	}


	init = async () => {

		//если метка в роуте найдена , то ставим её в selected. или уходит на базовый роут при неизвестной метке
		if (this.props.label && this.isLoading) {
			const label = await store.model.ViewLabel.find({
				where: {
					name: { ilike: this.props.match.params.label }
				}
			});
			this.label = label.length > 0 ? label[0] : this.props.history.push("/" + this.props.category.path);
			this.labelInRoute = this.label;
		};
		//если рубрика в роуте найдена , то ставим её в selected. или уходит на базовый роут при неизвестной рубрике
		if (this.props.topic && this.isLoading) {
			const topic = await store.model.Topic.find({
				where: {
					name: { ilike: this.props.match.params.topic }
				}
			});
			this.topic = topic.length > 0 ? topic[0] : this.props.history.push("/" + this.props.category.path);

		};

		const params = {
			willBePublished: true,
			labels: this.label ? [this.label.id] : [],
			topics: this.topic ? [this.topic.id] : [],
			category: this.props.category.id,
			magazine: this.props.magazineId,
			county: store.local.countyId,
			showOnMain: !store.local.countyId,
			fields: ['id', 'image', 'title', 'publishAt', 'commentsCount', 'viewsCount', 'countyName'],
			limit: INIT_COUNT,
			skip: 0,
			order: 'publishAt desc',

		};

		this.records = await store.model.ViewLabel.getArticles(params);
		this.totalCount = this.records[0] ? this.records[0].totalCount : 0;
		this.isLoading = false;
	};

	loadMore = async () => {
		const records = await store.model.ViewLabel.getArticles({
			willBePublished: true,
			labels: this.label ? [this.label.id] : [],
			topics: this.topic ? [this.topic.id] : [],
			category: this.props.category.id,
			magazine: this.props.magazineId,
			county: store.local.countyId,
			showOnMain: !store.local.countyId,
			fields: ['id', 'image', 'title', 'publishAt', 'commentsCount', 'viewsCount', 'countyName'],
			limit: this.records.length === 0 ? INIT_COUNT : MORE_COUNT,
			skip: this.records.length,
			order: 'publishAt desc',
		});

		this.records.push(...records);
		this.totalCount = records[0] ? records[0].totalCount : 0;
	};

	onLabelSelect = label => {
		if (this.label && this.label.id === label.id) {
			this.label = null;
		}
		else {
			// this.topic = null;
			this.label = label;
		}
		this.init();
	};
	onTopicSelect = topic => {
		if (this.topic && this.topic.id === topic.id) {
			this.topic = null;
		}
		else {
			// this.label = null;
			this.topic = topic;
		}
		this.init();
	};

	render() {

		if (this.isLoading) {
			return <div className="news-lenta">...</div>
		}
		const fields = this.props.category.fields || {}
		return <div className="news-lenta">
			<Breadcrumbs crumbs={[{
				title: this.props.category.name,
				path: "/" + this.props.category.path
			}]} />
			<div className="page-title">
				<h1> {this.props.category.name} </h1>
			</div>
			{fields.topic && fields.topic.active ? <Topics categoryId={this.props.category.id} topicInRoute={this.props.match.params.topic}
				selected={this.topic} onSelect={this.onTopicSelect} /> : null}
			{fields.labels && fields.labels.active ? <Labels categoryId={this.props.category.id} labelInRoute={this.labelInRoute}
				selected={this.label} onSelect={this.onLabelSelect} /> : null}

			<Articles articles={this.records} />
			
			<div className="load-more">
				<Button onClick={this.loadMore} disabled={this.records.length === this.totalCount}>Показать еще</Button>
			</div>
		</div>;
	}

}
