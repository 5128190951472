import React from 'react';
import { observer } from 'mobx-react';

import { PageForRoute } from './components';
import store from './store';

@observer
export default class FindPage extends React.Component {

	render() {
		return <PageForRoute route={store.route.path} />;
	}
	
}
