import React from 'react';
import { observable } from 'mobx';
import { observer } from 'mobx-react';
import { Link } from 'react-router-dom';

import store from '../../store';
import './style.scss';

@observer
export default class Events extends React.Component {

	@observable records = [];
	
	constructor(props) {
		super(props);
		this.init();
	}
	
	init = async () => {
		this.records = await store.model.Article.find({
			fields: ['id', 'title', 'image', 'isEvent'],
			where: {
				and: [
					{ isEvent: true },
					{ image: { neq: null }},
				],
			}
		});
	};
	
	render() {
		return <div className="banners-block">
			<div className="title">Афиша</div>
			{this.records.map(record => <Link key={record.id} to={`/news/${record.id}`}>
				<img src={record.downloadFile('image') + '?image=[{"resize":{"width":400,"height":240}}]'} alt="" />
				<strong>{record.title}</strong>
			</Link>)}
		</div>;
	}
	
}
