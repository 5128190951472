import React from 'react';
import PropTypes from 'prop-types';
import { observable } from 'mobx';
import { observer } from 'mobx-react';
import { withRouter, Switch, Route } from 'react-router-dom';
import store from 'client/store';
import Lenta from './Lenta';
import Article from '../article/Article';
import './style.scss';
import FindPage from '../../FindPage';

@withRouter @observer
export default class News extends React.Component {


	@observable error = null;
	@observable isLoading = true;
	@observable record = null;

	constructor(props) {
		super(props);
		this.init();
	}
	componentDidUpdate(prevProps, prevState, snapshot) {
		if (prevProps.match.params.path !== this.props.match.params.path ||
			prevProps.magazineId !== this.props.magazineId ||
			prevProps.countyId !== this.props.countyId) {
			this.error = null
			this.isLoading = true;
			this.init();
		}
	}

	init = async () => {

			const record = await store.model.Category.find({
				where: {
					path: this.props.match.params.path
				},
				fields: ['id', 'name', 'path', 'special','fields'],
				limit: 1
			})
			this.record = record[0];


		this.isLoading = false;
	};
	render() {
		const path = this.props.match.params.path	
		if (this.isLoading) return <div>...</div>
		if (!this.record) return <FindPage />



		return <div className="news-page">
			<Switch>
				<Route path={`/${path}`} exact> <Lenta category={this.record} /></Route>
				<Route path={`/${path}/label/:label`}><Lenta category={this.record} label/></Route>
				<Route path={`/${path}/topic/:topic`}><Lenta category={this.record} topic/></Route>
				<Route path={`/${path}/:text`}><FindPage/></Route>
				{/* <Route path={`/${path}/:id`} component={Article} /> */}
			</Switch>
		</div>;
	}

}
