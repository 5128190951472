import React from 'react';
import { relative } from 'client/helpers';
import { Link } from 'react-router-dom';
import store from 'client/store'

export const SearchCard = ({ record }) => {
	return <div className='search-card'>
		<Link to={`/article/${record.id}`} >
			<div className='card-wrapper'>
				<div className='image'>
					{record.image ?
						<img src={record.downloadFile('image') + '?image=[{"resize":{"width":400}}]'} alt="" />
						:
						<span className="no-image">Фото нет</span>
					}
				</div>
				<div className="content">
					<div className="top">
						<span className="category">{record.categoryName ? record.categoryName : ''}</span>
						{!store.ui.county && <span className='county'>{record.countyName}</span>}
						<span className="date">{relative(record.publishAt)}</span>
					</div>
					<div className="title">{record.title}</div>
				</div>
			</div>
		</Link>
	</div>
}