import React from 'react';
import { observable } from 'mobx';
import { observer } from 'mobx-react';

import store from 'client/store';
import './style.scss';

@observer
export default class CountyPage extends React.Component {

	@observable county = null;
	@observable category = null;
	@observable record = null;
	@observable isLoading = true;

	constructor(props) {
		super(props);
		this.init();
	}

	componentDidUpdate(prevProps, prevState, snapshot) {
		if (this.props.path !== prevProps.path) {
			this.load();
		}
	}

	init = async () => {
		if (store.local.countyId) {
			this.county = await store.model.County.findById(store.local.countyId);
		}
		await this.load();
	};

	load = async () => {
		this.isLoading = true;

		const categories = await store.model.Category.find({
			where: {
				single: true,
				path: this.props.path,
			}
		});
		this.category = categories.length > 0 ? categories[0] : null;

		if (this.category) {
			const where = {
				and: [
					{ categoryId: this.category.id },
				],
			};

			if (store.local.countyId) {
				where.and.push({ countyId: store.local.countyId });
			}
			else {
				where.and.push({ countyId: 1 });
			}

			const records = await store.model.Article.find({
				where,
				order: 'id desc',
				limit: 1,
			});

			this.record = records.length > 0 ? records[0] : null;
		}

		this.isLoading = false;
	};

	render() {
		if (this.isLoading) return null;
		if (!this.category) return null;

		return <div className="county-page">
			<div className="page-title">
				<h1>{this.category.name}</h1>
			</div>
			{this.record ?
				<div className="rich-text" dangerouslySetInnerHTML={{ __html: this.record.text }}/>
				:
				<div>Страница в разработке</div>
			}
		</div>;
	}

}
