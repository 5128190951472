import React from 'react';
import { observable } from 'mobx';
import { observer } from 'mobx-react';
import { Link } from 'react-router-dom';
import MediaQuery from 'react-responsive';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faChevronRight } from "@fortawesome/free-solid-svg-icons";
import icon from 'client/img/memory-icon.svg';
import store from 'client/store';
import { MemoryCard } from 'components';
import './style.scss';

@observer
export default class Memory extends React.Component {

	@observable records = [];
	@observable isLoading = true;

	constructor(props) {
		super(props);
		this.init();
	}

	init = async () => {
		const params = {
			category: 5,
			onlyPublished: true,
			county: store.local.countyId,
			onlyWithImage: true,
			limit: 4,
			order: 'id desc',
			fields: ['id', 'image', 'title', 'publishAt','commentsCount' , 'viewsCount','date0','date1']
		};
		this.records = await store.model.ViewLabel.getArticles(params);
		this.isLoading = false;
	};

	render() {
		if(this.records.length===0) return null
		return <div className="memory-main-container">
			<div className="memory-main">
			<div className='header'>
					<Link to="/memory" >
						<h2 className="main" ><img src={icon} alt="" />Слово памяти</h2>
					</Link>
					<Link to="/memory" className='right'>
						<div className="link">Посмотреть все <FontAwesomeIcon icon={faChevronRight} /></div>
					</Link>
				</div>
				<div className="cards">
					{this.records.slice(0, 2).map(record => <MemoryCard key={record.id} record={record} />)}
					<MediaQuery minWidth={1024}>
						{this.records.slice(2, 4).map(record => <MemoryCard key={record.id} record={record} />)}
					</MediaQuery>
				</div>
			</div>
		</div>;
	}

}
