import React from 'react';
import { Link } from 'react-router-dom';
import store from 'client/store';

const CountyMenu = ({ onClick, mobile = false, footer = false }) => (
	<>
		<div className="link" onClick={onClick}>
			<Link to="/news">Новости</Link>
		</div>
		<div className="link" onClick={onClick}>
			<Link to="/opinion">
				{store.ui.menuItems.find(item => item.path === 'opinion')?.title || 'Мнения/Тус санаа'}
			</Link>
		</div>
		{/* <div className="link" onClick={onClick}><Link to="/stories">Актуально</Link></div> */}
		<div className="link" onClick={onClick}>
			<Link to="/peoplegames">Игры народов</Link>
		</div>
		{mobile || footer ? (
			<div className="link" onClick={onClick}>
				<Link to="/specialprojects">Спецпроекты</Link>
			</div>
		) : (
			<div className="parent">
				Спецпроекты
				<div className="submenu">
					{store.ui.specialProjects.map(({ path, name }) => {
						return (
							<Link key={path} to={`/${path}`}>
								{name}
							</Link>
						);
					})}
				</div>
			</div>
		)}

		<div className="link red">
			<Link to="/partners">Партнерские материалы</Link>
		</div>
		<div className="link" onClick={onClick}>
			<Link to="/about">О районе</Link>
		</div>
		<div className="link" onClick={onClick}>
			<Link to="/counties">Районы</Link>
		</div>
		<div className="link" onClick={onClick}>
			<Link to="/media">Видео</Link>
		</div>
		<div className="link" onClick={onClick}>
			<Link to="/ads">Реклама</Link>
		</div>
		<div className="link" onClick={onClick}>
			<Link to="/contacts">Контакты</Link>
		</div>
	</>
);

export default CountyMenu;
