import React from 'react';
import { observer } from 'mobx-react';
import { withRouter, Switch, Route } from 'react-router-dom';

import loadable from '@loadable/component'
const List = loadable(() => import('./List'));
import './style.scss';

@withRouter @observer
export default class PollsArchive extends React.Component {

	render() {
		return <div className="polls-archive-page">
			<Switch>
				<Route path="/polls-archive" exact component={List} />
			</Switch>
		</div>;
	}

}
