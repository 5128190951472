import React from 'react';
import FacebookIcon from '-!svg-react-loader?name=Icon!client/img/social/facebook.svg';
import WhatsappIcon from '-!svg-react-loader?name=Icon!client/img/social/whatsapp.svg';
import TelegramIcon from '-!svg-react-loader?name=Icon!client/img/social/telegram.svg';
import TwitterIcon from '-!svg-react-loader?name=Icon!client/img/social/twitter.svg';
import VKIcon from '-!svg-react-loader?name=Icon!client/img/social/vk.svg';
import punycode from "punycode";

const Social = ({ title }) => {
	const url = `${window.location.protocol}//${punycode.toUnicode(window.location.host)}${window.location.pathname}`;

	console.log(title);

	return <div className="share-links">
		<a target="_blank" rel="noopener noreferrer"
			href={`https://www.facebook.com/sharer.php?u=${url}&quote=${title || ''}`}> <FacebookIcon /></a>
		<a target="_blank" rel="noopener noreferrer"
			href={`https://vk.com/share.php?url=${url}&title=${title || ''}`}><VKIcon /></a>
		<a target="_blank" rel="noopener noreferrer"
			href={`http://www.twitter.com/share?url=${url}&text=${title || ''}`}><TwitterIcon /></a>
		<a target="_blank" rel="noopener noreferrer"
			href={`https://telegram.me/share/url?url=${url}&text=${title || ''}`}><TelegramIcon /></a>
		<a target="_blank" rel="noopener noreferrer"
			// href={`https://wa.me/?text=${title} ${url}`}><WhatsappIcon /></a>
			href={`whatsapp://send?text=${title} ${url}`}><WhatsappIcon /></a>
	</div >
}
export default Social
