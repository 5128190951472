import { BigArticle, MidArticle, Banner285x366 } from 'components';
import './style.scss';
import MediaQuery from 'react-responsive';
import React from 'react';

export default ({ articles, noBanner = false }) => {
	if (!articles) return null;
	return <div className="articles">
		{articles.map((article, i) => {
			if (i === 0) return <BigArticle key={article.id} article={article} />
			if (i === 1) return <MidArticle key={article.id} article={article} />
			if (i === 2) {
				return <React.Fragment key="desktop">
					{!noBanner && <MediaQuery minWidth={1200}><div className="block"><Banner285x366 /></div></MediaQuery>}
					<MidArticle key={article.id} article={article} />
				</React.Fragment>;
			}
			return <MidArticle key={article.id} article={article} />
		})}
	</div>
}