import React from 'react';
import { observable } from 'mobx';
import { observer } from 'mobx-react';

import { getYoutubeId } from 'client/helpers';
import store from 'client/store';

@observer
export default class Video extends React.Component {

	@observable video = null;

	constructor(props) {
		super(props);
		this.init();
	}

	init = async () => {
		const media = await store.model.Media.find({
			where: { albumId: this.props.album.id },
			order: 'id desc',
			limit: 1,
		});

		this.video = media[0];
	};

	render() {
		if (!this.video) return null;

		const youtubeId = getYoutubeId(this.video.videoLink);
		if (youtubeId) {
			return <div className="video">
				<div className="wrapper">
					<iframe width="320" height="200" src={`https://www.youtube.com/embed/${youtubeId}`} frameBorder="0"
					        allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
					        allowFullScreen />
				</div>
			</div>;
		}
		return null;
	}

}
