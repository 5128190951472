import React from 'react';
import PropTypes from 'prop-types';
import { observer } from 'mobx-react';
import classNames from 'classnames';
import { Link } from 'react-router-dom';
import PlayBtn from '-!svg-react-loader?name=Icon!client/img/play-btn.svg';
import './style.scss';
import { Info } from '../info';
import { JPEG_QUALITY } from 'client/constants';

@observer
export default class MediaCard extends React.Component {

	static propTypes = {
		media: PropTypes.object.isRequired,
		size: PropTypes.oneOf([ 'big', 'small' ]),
	};

	constructor(props) {
		super(props);
	}

	render() {
		const { media, size } = this.props;

		const linkProps = {
			key: `media${media.id}`,
			to: `/media/${media.id}`,
			className: classNames('media-card', size),
		};

/*
		const imgSrc = media.type === 'photo' ?
			media.downloadFile('image') + '?image=[{"resize":{"width":400}}]'
			:
			media.type === 'video' ? `https://img.youtube.com/vi/${getYoutubeId(media.videoLink)}/0.jpg` : null;
*/

		const imgSrc = media.downloadFile('image') + `?image=[{"resize":{"width":400}}${JPEG_QUALITY}]`;

		return <Link {...linkProps}>
			<div className="image">
				<img src={imgSrc} alt="" />
				<div className="bg">
					<div className="title">{media.name}</div>
					<div className="info">
						<Info record={media}/>
					</div>
					{media.type === 'video' && <PlayBtn className="play-btn" />}
				</div>
			</div>
		</Link >
	}
}
