import React from 'react';
import { observer } from 'mobx-react';
import { Link } from 'react-router-dom';
import { formatDate } from 'client/helpers';
import { JPEG_QUALITY } from 'client/constants';

@observer
export default class EventCard extends React.Component {

	render() {
		const { record } = this.props;

		if (!record) return <div className="event-card"/>;
		const showDate = !!record.date0 && !!record.date1;

		return <Link to={`/events/${record.id}`} className="event-card">
			<div className="image">
				<img src={record.downloadFile('image') + `?image=[{"resize":{"width":285}}${JPEG_QUALITY}]`} alt="" />
			</div>
			{showDate &&<div className="dates">
				{record.date0 ? formatDate(record.date0, 'd') : ''}
				{record.date0 && record.date1 ? ' - ' : ''}
				{record.date1 ? formatDate(record.date1, 'd') : ''}
			</div>}
			<div className="bg"/>
			<div className="name">
				{record.title}
				{record.subtitle && <div className="place">{record.subtitle}</div>}
			</div>
		</Link>;
	}

}
