export const VERTICAL = 'vertical';
export const HORIZONTAL = 'horizontal';

export default {
	topLeft: [ VERTICAL, 'top', 'left' ],
	top: [ VERTICAL, 'top', 'center' ],
	topRight: [ VERTICAL, 'top', 'right' ],

	leftTop: [ HORIZONTAL, 'left', 'top' ],
	left: [ HORIZONTAL, 'left', 'center' ],
	leftBottom: [ HORIZONTAL, 'left', 'bottom' ],

	rightTop: [ HORIZONTAL, 'right', 'top' ],
	right: [ HORIZONTAL, 'right', 'center' ],
	rightBottom: [ HORIZONTAL, 'right', 'bottom' ],

	bottomLeft: [ VERTICAL, 'bottom', 'left' ],
	bottom: [ VERTICAL, 'bottom', 'center' ],
	bottomRight: [ VERTICAL, 'bottom', 'right' ],
};
