import React from 'react';
import { Link } from 'react-router-dom';
import { relative } from 'client/helpers';
import t from 'i18n';
import { Info } from '../info';
import { JPEG_QUALITY } from 'client/constants';

export default ({ article }) => {
	if (!article) return <div className="big-article"><div className="content"/></div>;

	return <Link to={`/article/${article.id}`} className="big-article">
		<div className="county">{article.countyName || (article.county ? article.county.name : 'Республика')}</div>
		<div className="content">
			{article.image ?
				<img src={article.downloadFile('image') + `?image=[{"resize":{"width":590}}${JPEG_QUALITY}]`} alt=""/>
				:
				<span className="no-image">{t('noImage')}</span>
			}
			<div className="bg"/>
			<div className="title">{article.title}</div>
			<div className="info">
				<div className="date">{relative(article.publishAt)}</div>
				<div className="article-info">
				<Info record={article} />
				</div>
			</div>
		</div>
	</Link>;
}