import React from 'react';
import { observable } from 'mobx';
import { observer } from 'mobx-react';
import { Link } from 'react-router-dom';
import MediaQuery from 'react-responsive';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faChevronRight } from "@fortawesome/free-solid-svg-icons";
import icon from 'client/img/memory-icon.svg';
import store from 'client/store';
import EventCard from './EventCard';
import './style.scss';

@observer
export default class Events extends React.Component {

	@observable records = [];
	@observable isLoading = true;

	constructor(props) {
		super(props);
		this.init();
	}

	init = async () => {
		const where = {
			and: [
				{ categoryId: 4 },
				{ image: { neq: null } },
				{ countyId: store.local.countyId || 1 }
			]
		};

		this.records = await store.model.Article.find({
			where,
			order: 'id desc',
			limit: 4,
			fields: ['id', 'image', 'title', 'subtitle', 'date0', 'date1', 'countyId']
		});

		this.isLoading = false;
	};

	render() {
		let records = this.records;
		if (records.length === 0) return null;
		if (records.length < 4) records = [...records, ...(new Array(4 - records.length))];

		return <div className="events-main-container">
			<div className="events-main">
				<div className='header'>
					<Link to="/events" >
						<h2 className="main" ><img src={icon} alt="" /> Афиша</h2>
					</Link>
					<Link to="/events" className='right'>
						<div className="link">Посмотреть все <FontAwesomeIcon icon={faChevronRight} /></div>
					</Link>
				</div>
				<div className="cards">
					{records.slice(0, 2).map((record, i) => record && <EventCard key={i} record={record} />)}
					<MediaQuery minWidth={1024}>
						{records.slice(2, 4).map((record, i) => record && <EventCard key={i} record={record} />)}
					</MediaQuery>
				</div>
			</div>
		</div>;
	}

}
