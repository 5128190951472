import React from 'react';
import PropTypes from 'prop-types';
import { observable } from 'mobx';
import { observer } from 'mobx-react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEye } from '@fortawesome/free-solid-svg-icons';
import { SECURE } from '.';
import store from 'client/store';
import './style.scss';

const relations = {
	Article: 'articleId',
	ViewArticle: 'articleId',
	Album: 'albumId',
	ViewAlbum: 'albumId',
	Media: 'mediaId',
	ViewMedia: 'mediaId',
};

@observer
export default class ViewsCount extends React.Component {
	@observable count;
	static propTypes = {
		record: PropTypes.object.isRequired,
	};
	constructor(props) {
		super(props);
		this.modelName = props.record.MODEL.INFO.name;
		this.foreignKey = relations[this.modelName];
		this.init();
	}

	init = async () => {
		const payload = {
			id: store.visitorId,
			[this.foreignKey]: this.props.record.id,
		};
		this.count = await store.model.View.getCount({
			...payload,
			secure: SECURE,
		});
	};

	render() {
		if (typeof this.count === 'number')
			return (
				<div className="views-count">
					{/* <FontAwesomeIcon icon={faEye} /> &nbsp; {this.count} */}
				</div>
			);
		return <></>;
	}

}
